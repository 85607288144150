import React, { useEffect, useState } from "react";
import "../CSS/profile.css";
import people from "../Assets/images/people.jfif"
import apiRequest from "../APICall";
import toast from "react-hot-toast";
import Loader from "../Components/Loader";
function ValuationProfilePage() {

    const [lookUpRoleIdById, setLookUpRoleIdById] = useState([]);
    const userData = JSON.parse(localStorage.getItem("userData"));
    
    const [jewellerData, setJewellerData] = useState([]) 
    const [isLoader, setIsLoader] = useState(false);

    const loggedinId = userData ? userData.ValuerMasterId : "";
    const roleId = userData ? userData.ValuerRoleId : "";
    const jewellerId = userData ? userData.JewellerId :"";

          // function to get jeweller data
    const getJewellerData = async () => {
        setIsLoader(true);
            try {
              const res = await apiRequest(
                "GET",
                `getJewellerDataById?jewellerId=${jewellerId}`,
                null,
                true,
                false
              );
              setJewellerData(res)
              setIsLoader(false);
            } catch (error) {
                setIsLoader(false);
              toast.error("Try after sometime");
            }
    };

    const getRoleName = () =>{
        setIsLoader(true);
        apiRequest("GET", `lookUpRoleIdById?RoleId=${roleId}`, null, true, false)
            .then((res) => {
                setLookUpRoleIdById(res);
                setIsLoader(false);
            })
            .catch(function (error) {
                setIsLoader(false);
                toast.error("Try after sometime");
            });
    }
    
    useEffect(() => {
        getJewellerData();
        getRoleName();
    }, [])

    return (
        <>
            <div className="profilemain">
                <section className="sectionB">
                    <h1 className="profilehead">User Profile</h1>

                    <div className="profile-container" style={{ display: "flex" }}>
                        <section className="img-container">
                            <img src={people} className="imgg" alt="#" />
                        </section>
                        <section className="user-details">
                            {/* user data */}
                            <h4 >User Name : &ensp;{userData ? userData.ValuerName : ""}</h4>
                            <h4 >Role : &ensp;{lookUpRoleIdById[0]?.RoleName} </h4>
                            <h4 >Mobile No : &ensp;{userData ? userData.MobileNumber : ""} </h4>
                            {/* jeweller data */}
                            <h4>Jeweller Name: &ensp; {jewellerData ? jewellerData[0]?.JewellerName : ""}</h4>
                            <h4>Jeweller Address: &ensp; {jewellerData ? jewellerData[0]?.Address : ""}</h4>
                        </section>

                    </div>
                </section>
            </div>
            {isLoader && <Loader/>}
        </>
    );
}

export default ValuationProfilePage;


// import React from "react";
// import "./profile.css";
// import image from "../../assets/images/imagesignup.jpg";
// import person from "../../assets/images/person.png";

// function ValuationProfilePage() {
//     return (
//         <>
//             <div className="profilemain">


//                 <section className="sectionA">

//                     <h1 className="profilehead">Jewellery Valuation Service</h1>
//                     <div className="comp2profile">
//                         <img className="imagevaluation" src={image} alt="Valuation images" />
//                         <div style={{ padding: '5%' }}>
//                             <h2>Our Services</h2><br />
//                             <ul>
//                                 <li>Valuation of Imitiation of jewellery</li><br />
//                                 <li>Valuation of Real jewellery</li>
//                                 {/* <li>Service 3</li> */}
//                             </ul>
//                         </div>
//                     </div>

//                     <p className="profilepara">Jewellery valuation service is a specialized service that involves the appraisal of the value of jewellery.
//                         It is typically used for insurance purposes, estate planning, tax assessment, and sale of jewellery.</p>

//                     <p className="profilepara">During the valuation process, a certified jewellery appraiser examines the jewellery to determine its quality,
//                         authenticity, and value. They take into account various factors such as the type of metal, gemstones,
//                         and the craftsmanship of the jewellery.</p>

//                     <p className="profilepara">
//                         The jewellery valuation service can help you determine the current market value of your jewellery and provide
//                         you with an accurate appraisal report that can be used for various purposes such as insurance claims, inheritance
//                         disputes, and tax assessments.
//                     </p>

//                     <p className="profilepara">
//                         The jewellery valuation report typically includes information such as the type of jewellery, the metal and gemstone content,
//                         the weight of the jewellery, and a detailed description of its condition. The report also includes photographs of the jewellery
//                         and an estimated value.

//                         It is important to choose a reputable jewellery valuation service that has certified and experienced appraisers who use internationally
//                         recognized standards for valuation.
//                     </p>




//                 </section>


//                 <section className="sectionB">
//                     <h1 className="profilehead"> User Profile</h1><br/>

//                     <div style={{display:'flex',justifyContent:'space-between'}}>
//                         <section>
//                     <h4 >User Name : &ensp;Jignesh maru </h4>
//                     <h4 >Jeweller ID : &ensp; 23 </h4>
//                     <h4 >Role : &ensp;Admin </h4>
//                     <h4 >Email ID : &ensp;jignesh.maru@reveation.com </h4>
//                     <h4 >Mobile No : &ensp;7573834222 </h4>
//                         </section>
//                         <section>
//                             <img src={person} alt="#"/>
//                         </section>
//                     </div>
//                 </section>
//             </div>
//         </>
//     );
// }

// export default ValuationProfilePage;