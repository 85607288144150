import React, { useState, useEffect } from 'react';
import "../../CSS/banner.css"
import * as yup from "yup";
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import apiRequest from "../../APICall";
import Sidebar from "../SettingsPage/Sidebar";
import { getDisplayDate, getMonthDateYear } from '../../DateTimeUtils';
import { BiEditAlt, BiTrash } from 'react-icons/bi';
import { Divider, Grid, Tooltip } from '@material-ui/core';
import MySelect from '../../Components/AutoComplete';
import { GridIcons, actionColumnStyle, options, style } from '../../Components/GridConfig';
import MaterialTable from 'material-table';
import Loader from '../../Components/Loader';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ManageAdminPage = () => {

    const [adminList, setAdminList] = useState([]);
    const [isShown, setIsShown] = useState(false);
    const [isTableShown, setIsTableShown] = useState(true);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [jewellerList, setJewellerList] = useState([]);
    const [selectedJeweller, setSelectedJeweller] = useState("");
    const [errorsJeweller, setErrorsJeweller] = useState(false);
    const [isLoader, setIsLoader] = useState(false);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const loggedinId = userData ? userData.ValuerMasterId : "";
    const roleId = userData ? userData.ValuerRoleId : "";

    useEffect(() => {
        adminGetData();
        jewellerGetData();
    }, []);

    const adminGetData = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("GET", `admingetdata?loggedinId=${loggedinId}&roleId=${roleId}`, null, true, false);
            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
            }));
            setIsLoader(false);
            setAdminList(modifiedRes);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const jewellerGetData = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("GET", `jewellerGetData`, null, true, false);
            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
                label: item.JewellerName,
                value: item.JewellerMasterId
            }));
            setIsLoader(false);
            setJewellerList(modifiedRes);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const initialValues = {
        adminName: "",
        mobile: "",
        password: "",
    };

    const validationSchema = yup.object({
        adminName: yup
            .string()
            .required("Please enter Admin name"),
        mobile: yup.string()
            .required("Please enter Mobile Number")
            .min(10, "Mobile Number should be 10 digit.")
            .max(10, "Mobile Number should be 10 digit.")
            .matches(phoneRegExp, "Phone number is not valid"),
        password: yup.string()
            .required("Please enter password")
            .min(8, "Password should be greater than 8 characters."),
    });

    const { values, handleSubmit, handleChange, errors, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setIsLoader(true);
                if (selectedBanner) {
                    apiRequest("POST", `updateadmindata`,
                        {
                            adminName: values.adminName,
                            mobile: values.mobile,
                            password: values.password,
                            valuerMasterId: selectedBanner.ValuerMasterId,
                            JewellerMasterId: values.selectedJeweller,
                            loggedinId
                        },
                        true, false)
                        .then((res) => {
                            if (res.status === 200) {
                                toast.success(res.message);
                                setIsLoader(false);
                                adminGetData();
                                resetForm();
                                setIsShown(false);
                                setSelectedBanner(null);
                            } else {
                                setIsLoader(false);
                                toast.error(res.message);
                            }
                        })
                        .catch(function (error) {
                            setIsLoader(false);
                            toast.error("Try after sometime")
                        });
                } else {
                    apiRequest("POST", `addadmindata`,
                        {
                            adminName: values.adminName,
                            mobile: values.mobile,
                            password: values.password,
                            JewellerMasterId: values.selectedJeweller,
                            loggedinId
                        },
                        true, false)
                        .then((res) => {
                            if (res.status === 200) {
                                toast.success(res.message);
                                adminGetData();
                                resetForm();
                                setIsShown(false)
                                setIsLoader(false);
                            } else if (res.status === 400) {
                                setIsLoader(false);
                                toast.error(res.message);
                            } else {
                                setIsLoader(false);
                                toast.error(res.message);
                            }
                        })
                        .catch(function (error) {
                            setIsLoader(false);
                            toast.error("Try after sometime")
                        });
                }
            } catch (error) {
                toast.error("Try after sometime")
            }
        }
    });

    const handleEdit = (banner) => {
        setSelectedBanner(banner);
        setIsShown(true);
        setSelectedJeweller(banner.JewellerId)
        setValues({
            adminName: banner.ValuerName,
            mobile: banner.MobileNumber,
            password: banner.Password,
            selectedJeweller: banner.JewellerId,
        });
    };

    const columns = [
        {
            title: "Sr.No",
            field: "index"
        },
        {
            title: "ADMIN NAME",
            field: "ValuerName",
        },
        {
            title: "MOBILE NUMBER",
            field: "MobileNumber",
        },
        {
            title: "PASSWORD",
            field: "Password",
        },
        {
            title: "JEWELLER",
            field: "SuperAdmin",
        },
        {
            title: "ACTION",
            ...actionColumnStyle,
            render: ({ tableData: { id } }) => (
                <div className="table-edit-controls">
                    <Tooltip title="Click to edit" style={{ cursor: "pointer" }}>
                        <BiEditAlt onClick={() => handleEdit(adminList[id])} />
                    </Tooltip>
                </div>
            ),
            printable: false,
        },
    ]

    return (
        <>
            <div className="settingsmaindiv">
                <section className="set-secBs">
                    <div className='banner-container'>
                        <div className='isInline'>
                        <h1 style={{ display:"flex" ,textAlign: "center", alignItems:"center", paddingLeft:"1%" }}><Sidebar />Admin</h1>
                            <button className="add-ornament-btn"
                                onClick={() => {
                                    setIsShown(true);
                                    resetForm();
                                }}>
                                Add Admin
                            </button>
                        </div><Divider /><br />
                        {!isShown && isTableShown && (
                            <section className="tablecontainer">
                                <MaterialTable
                                    icons={GridIcons}
                                    title={`Admin List`}
                                    columns={columns}
                                    data={adminList}
                                    options={options}
                                    style={style}
                                />
                            </section>
                        )}

                        {isShown && (
                            <form onSubmit={handleSubmit} style={{ border: "none" }}>
                                <div className="banner-card">
                                    <Grid container spacing={6} style={{ alignItems: "center" }}>
                                        <Grid item xs={12} md={6}>
                                            <label>Admin Name:</label>
                                            <input
                                                type="text"
                                                name="adminName"
                                                id="adminName"
                                                className='inputFieldGrid'
                                                placeholder='Admin Name'
                                                value={values.adminName}
                                                onChange={handleChange}
                                            />
                                            {errors.adminName && (<div className="errorcss">{errors.adminName}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <label>Mobile Number:</label>
                                            <input
                                                type="text"
                                                name="mobile"
                                                className='inputFieldGrid'
                                                id="mobile"
                                                placeholder='Mobile Number'
                                                value={values.mobile}
                                                onChange={handleChange}
                                            />
                                            {errors.mobile && (<div className="errorcss">{errors.mobile}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <label>Password:</label>
                                            <input
                                                type="text"
                                                name="password"
                                                id="password"
                                                placeholder='Password'
                                                className='inputFieldGrid'
                                                value={values.password}
                                                onChange={handleChange}
                                            />
                                            {errors.password && (<div className="errorcss">{errors.password}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <label>Jeweller: *</label>
                                            <MySelect
                                                options={jewellerList}
                                                placeholder="Select Jeweller"
                                                value={selectedJeweller}
                                                onChange={(value) => {
                                                    setSelectedJeweller(value);
                                                    setErrorsJeweller(true);
                                                }}
                                            />
                                            {errorsJeweller && (<div className="errorcss">Please select Jeweller</div>)}
                                        </Grid>
                                    </Grid>

                                    <div className='isInline-1'>
                                        <button onClick={() => {
                                            setIsShown(false);
                                            setSelectedBanner(null); // Clear selected banner if cancel is clicked
                                        }}>
                                            Cancel
                                        </button>
                                        <button type="submit">
                                            {selectedBanner ? "Update Admin" : "Add Admin"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </section>
            </div>
            {isLoader && <Loader />}
        </>
    );
};

export default ManageAdminPage;
