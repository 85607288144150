import React, { useState, useEffect, useRef } from "react";
import "../CSS/valuationReport.css";
import apiRequest from "../APICall";
import toast from "react-hot-toast";
import { getDisplayDate, getDateMonthYear } from "../DateTimeUtils";
import { ToWords } from 'to-words';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../Components/Loader";

const GetCertificate = () => {

    const location = useLocation();
    const valuationData = location.state.valuationData;
    const navigate = useNavigate();

    const [isLoader, setIsLoader] = useState(false);

    const toWords = new ToWords();

    const [awbNumber, setAWBNumber] = useState("");
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [jeweller, setJeweller] = useState("");
    const [jewellerAddress, setJewellerAddress] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [purposeOfValuation, setPurposeOfValuation] = useState("");
    const [valuationDate, setValuationDate] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerMobileNumber, setCustomerMobileNumber] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [certificateData, setCertificateData] = useState([]);
    const [ornamentList, setOrnamentList] = useState([]);
    const [realJewellery, setRealJewellery] = useState([]);
    const [imitationJewellery, setImitationJewellery] = useState([]);

    const [certificateNumber, setCertificateNumber] = useState(valuationData?.CertificateNumber);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const loggedinId = userData ? userData.ValuerMasterId : "";
    const valuerName = userData ? userData.ValuerName : "";
    const roleId = userData ? userData.ValuerRoleId : "";
    const jewellerId = userData ? userData.JewellerId : "";

    let totalNetReal = 0;
    let totalGrossReal = 0;
    let totalQtyReal = 0;
    let totalAmountReal = 0;
    let totalAmountImitation = 0;
    let totalQtyImit = 0;
    let totalGrossImit = 0;
    let totalNetImit = 0;


    const getCertificateDataById = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest(
                "GET",
                `getCertificateDataById?certificateNumber=${valuationData?.CertificateNumber}`,
                null,
                true,
                false
            );

            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
                ValuationReport: JSON.parse(item.ValuationReport),
            }));
            const realJewelleryData = modifiedRes[0].ValuationReport?.filter(
                (item) => item.Jewellarytype === "Real Jewellary"
            );
            const imitationJewelleryData = modifiedRes[0].ValuationReport?.filter(
                (item) => item.Jewellarytype === "Imitation"
            );
            setCertificateData(modifiedRes[0]);
            setAWBNumber(modifiedRes[0].AWBNumber === "" ? "-" : modifiedRes[0].AWBNumber);
            setInvoiceNumber(modifiedRes[0].InvoiceNumber === "" ? "-" : modifiedRes[0].InvoiceNumber);
            setJeweller(modifiedRes[0].JewellerName);
            setJewellerAddress(modifiedRes[0].Address);
            setRegistrationNumber(modifiedRes[0].RegistrationNumber)
            setPurposeOfValuation(modifiedRes[0].PurposeOfValuationName);
            setValuationDate(modifiedRes[0].DateOfValuation);
            setJewellerAddress(modifiedRes[0].Address);
            setJewellerAddress(modifiedRes[0].Address);
            setCustomerName(modifiedRes[0].CustomerName);
            setRealJewellery(realJewelleryData);
            setImitationJewellery(imitationJewelleryData);
            setCustomerMobileNumber(modifiedRes[0].MobileNumber);
            setCustomerAddress(modifiedRes[0].Address1 + ", " + modifiedRes[0].City + ", " + modifiedRes[0].State + ", " + modifiedRes[0].State + ", " + modifiedRes[0].Country + ", " + modifiedRes[0].PinCode)
            setIsLoader(false);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const ornamentGetData = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest(
                "GET",
                `ornamentsgetdata`,
                null,
                true,
                false
            );
            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
            }));
            setOrnamentList(modifiedRes);
            setIsLoader(false);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    useEffect(() => {
        getCertificateDataById();
        ornamentGetData();
    }, []);

    //   useEffect(() => {
    //     const beforeUnloadHandler = () => {
    //       localStorage.removeItem("CustDetails");
    //       localStorage.removeItem("tableData");
    //     };
    //     window.addEventListener("beforeunload", beforeUnloadHandler);
    //     return () => {
    //       window.removeEventListener("beforeunload", beforeUnloadHandler);
    //     };
    //   }, []);

    const handleSubmit = async () => {
        try {
            const contentPage = document.getElementById("content");
            const content1Page = document.getElementById("content1");
            const content2Page = document.getElementById("content2");
            let content1Canvas;
            let content2Canvas;
            const contentCanvas = await html2canvas(contentPage);

            if (realJewellery && realJewellery.length !== 0) {
                content1Canvas = await html2canvas(content1Page);
            }
            if (imitationJewellery && imitationJewellery.length !== 0) {
                content2Canvas = await html2canvas(content2Page);
            }

            const pdf = new jsPDF({
                unit: "px",
                format: "a3",
            });

            pdf.addImage(contentCanvas.toDataURL("image/png"), "PNG", 0, 60, 650, 600, null, "FAST", 0);

            if ((realJewellery && realJewellery.length !== 0) || (imitationJewellery && imitationJewellery.length !== 0)) {
                if (realJewellery && realJewellery.length !== 0) {
                    pdf.addPage();
                    pdf.addImage(content1Canvas.toDataURL("image/png"), "PNG", 0, 60, 650, 600, null, "FAST", 0);
                }
                if (imitationJewellery && imitationJewellery.length !== 0) {
                    pdf.addPage();
                    pdf.addImage(content2Canvas.toDataURL("image/png"), "PNG", 0, 60, 650, 600, null, "FAST", 0);
                }
            }
            // const pdfBlob = pdf.output("blob");
            // const fileUploadResult = await fetch(url, {
            //     method: "PUT",
            //     body: pdfBlob,
            // });
            // const { ok = false, status = null } = fileUploadResult;
            // if (ok && status == "200") {
                const res = await apiRequest("POST", `valuationUpdateData`, {
                    // pdf: key ? key : null,
                    certificateNo: certificateNumber,
                    valuerId: loggedinId,
                    jewellerId: jewellerId,
                    dateOfValuation: valuationDate,
                }, true, false);

                if (res.status === 200) {
                    toast.success(res.message);
                    pdf.save(`${certificateNumber}.pdf`);
                    navigate('/valuationHistory');
                } else {
                    toast.error(res.message);
                }
            // }
        } catch (error) {
            toast.error("Try after sometime");
        }
    };

    const getUploadFileURL = (type) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await apiRequest("GET", `getAWSFileUploadURL?type=${type}&screen=MyValuation`, null, true, false);
                resolve(res.data);
            } catch (error) {
                reject(error);
            }
        });
    };

    return (
        <div>
            <div id="content" >
                <div className="HomepagesectionB" style={{ overflowX: "auto" }}>
                    <div className="HomesectionA"></div>
                    <div className="Apart1">
                        <div className="cont">
                            <p>CERTIFICATE NO : </p>
                            <input
                                className="inputfields"
                                type="text"
                                value={certificateNumber}
                                readOnly
                            />
                        </div>
                        <div className="cont">
                            <p>A.W.B. NO :</p>
                            <input
                                className="inputfields"
                                type="text"
                                value={awbNumber}
                                readOnly
                            />
                        </div>
                        <div className="cont">
                            <p>INVOICE NO : </p>
                            <input
                                className="inputfields"
                                type="text"
                                value={invoiceNumber}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="secBhead" >
                        <center >
                            <h3 className="f8">FORM 08</h3>
                            <br />
                            {/* <p>See Rule 8D</p> */}
                            <p>Report of Valuation of Jewellery</p>
                            <br />
                        </center>
                    </div>
                    <section>
                        <table className="table report-table">
                            <tbody>
                                <tr className="tr-1">
                                    <th className="thruser">Name of Registered valuer</th>
                                    <th className="rno">Registration No.</th>
                                </tr>

                                <tr>
                                    <td className="thruser">{jeweller + ", " + jewellerAddress}</td>
                                    <td>
                                        {registrationNumber}
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ textAlign: "left" }}>1. Purpose for which valuation is made. </td>
                                    <td className="wbg">
                                        {purposeOfValuation}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>2. Date as on which valuation is made.</td>
                                    <td className="wbg">
                                        {getDisplayDate(valuationDate)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>3. Name Of Owner(s) the Jewellery </td>
                                    <td className="wbg">
                                        {customerName}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>4. If asset is under joint ownership/ co-ownership</td>
                                    <td>
                                        <p>
                                            <b>As per list attached</b>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>5. Description of each item of jewellery.</td>
                                    <td>" "</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>6. Total gross weight of each item of jewellery.</td>
                                    <td>" "</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        7. Net Weight of precious metal,such as gold, silver,
                                        Platinum etc. in each item of jewellery.
                                    </td>
                                    <td>" "</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        8. Description of each precious or semi precious stone.
                                        Whether or not set in any ornament, wearning appeal etc.{" "}
                                    </td>
                                    <td>" "</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        9. Weight of each such precious or semi precious stone in
                                        carats.
                                    </td>
                                    <td>" "</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        10. Value of each precious or semi precious stone and the
                                        total Value Of such Stones.
                                    </td>
                                    <td>" "</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        11. Value Of the precious metal content in all the item of
                                        Jewellery.
                                    </td>
                                    <td>" "</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        12. Total Value of jewellery.[ The valuer should discuss the
                                        Special features, if any of the jewellery, such as its
                                        antique aethetic value, aesthic value etc.]
                                    </td>
                                    <td>---</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}>
                                        13. I hereby declare that <br />
                                        (a) The information Furnished above is true and correct to
                                        best of my knowledge and belief. <br />
                                        (b) I have no direct or indirect interest in the jewellery
                                        Valued. <br />
                                        (c) I have personally inspected the jewellery.{" "}
                                        <br />
                                        (d) I have not been convicted of any offence and sentenced
                                        to a term of imprisonment.{" "}
                                    </td>
                                    <td>
                                        <p style={{ paddingTop: "15%" }}>
                                            *This is a computer-generated document. No signature is
                                            required.
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
            {realJewellery && realJewellery.length > 0 && (
                <div id="content1" >
                    <div className="HomepagesectionB" style={{ overflowX: "auto" }}>
                        <div className="VR">
                            <center style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {" "}
                                <h1 className="head12">VALUATION REPORT</h1>&nbsp;
                                <h2 className="head12" style={{}}>(For Real Jewellery) </h2>
                            </center>
                            <center>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <th>NAME :-</th>
                                        <th style={{ width: "48rem" }} className="name-th">
                                            {customerName}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>ADDRESS:-</td>
                                        <td>
                                            {customerAddress + ", "}
                                            Mobile No. {customerMobileNumber}
                                        </td>
                                    </tr>
                                </table>
                            </center>

                            <center className="TableDescription">
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th> DESCRIPTION</th>
                                        <th>QTY.</th>
                                        <th>PURITY</th>
                                        <th>GROSS</th>
                                        <th>NET WT.(Gms) </th>
                                        <th>CT. ST.</th>
                                        <th>RATE</th>
                                        <th>AMOUNT</th>
                                    </tr>
                                    {realJewellery.map((item, index) => {
                                        const ornament = ornamentList?.find(
                                            (ornament) => ornament.OrnamentsMasterId == item.Ornament
                                        );
                                        const amount = item.Net * item.Rate;
                                        totalAmountReal += amount;
                                        totalQtyReal += parseInt(item.Qty);
                                        totalGrossReal += parseFloat(item.Gross);
                                        totalNetReal += parseFloat(item.Net);
                                        return (
                                            <tr key={index}>
                                                <td style={{ textAlign: "right" }}>{index + 1}</td>
                                                <td>{ornament?.OrnamentName}</td>
                                                <td style={{ textAlign: "right" }}>{item.Purity}</td>
                                                <td style={{ textAlign: "right" }}>{item.Qty}</td>
                                                <td style={{ textAlign: "right" }}>{item.Gross}</td>
                                                <td style={{ textAlign: "right" }}>{item.Net}</td>
                                                <td style={{ textAlign: "right" }}>{item.CTST}</td>
                                                <td style={{ textAlign: "right" }}>{item.Rate}</td>
                                                <td style={{ textAlign: "right" }}>{amount.toFixed(0)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td></td>
                                        <td>TOTAL</td>
                                        <td style={{ textAlign: "right" }}>{totalQtyReal}</td>
                                        <td></td>
                                        <th style={{ textAlign: "right" }}>{totalGrossReal.toFixed(0)}</th>
                                        <th style={{ textAlign: "right" }}>{totalNetReal.toFixed(0)}</th>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "right" }}> ₹ {totalAmountReal.toFixed(0)}</td>
                                    </tr>
                                </table>
                            </center>
                            <table>
                                <tr>
                                    <td style={{ width: "20%" }}>In Words:-</td>
                                    <td style={{ width: "100vw" }}>
                                        {toWords.convert(totalAmountReal, { currency: true }).toUpperCase()}
                                    </td>
                                </tr>
                            </table>
                            <center>
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td>
                                            Valuation based on Market Value as on{" "}
                                            <b>{getDateMonthYear(valuationDate)}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>
                                                The valuation was done for the jewellery as presented to me
                                                at the time of valuation. Hence, the responsibility of
                                                valuation is binded <br />
                                                for the jewellery presented as on date of valuation
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>
                                                We herby declare that we have no direct or indirect interest
                                                in the said Jewellery and all the information here are true
                                                and correct to
                                                <br /> the best of our knowledge and belief; which are based
                                                on the facts and information provided by
                                                Client/Owner/Management.
                                            </b>
                                        </td>
                                    </tr>
                                </table>
                            </center>
                        </div>
                    </div>
                </div>
            )
            }

            {
                imitationJewellery && imitationJewellery.length > 0 && (
                    <div id="content2" >
                        <div className="HomepagesectionB" style={{ overflowX: "auto" }}>
                            <div className="VR">
                                <center style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {" "}
                                    <h1 className="head12">VALUATION REPORT</h1>&nbsp;
                                    <h2 className="head12">(For Imitation) </h2>
                                </center>
                                <center>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <th>NAME :-</th>
                                            <th style={{ width: "48rem" }}>
                                                {customerName}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>ADDRESS:-</td>
                                            <td>
                                                {customerAddress + ", "}
                                                Mobile No.
                                                {customerMobileNumber}
                                            </td>
                                        </tr>
                                    </table>
                                </center>

                                <center>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th> DESCRIPTION</th>
                                            <th>QTY.</th>
                                            <th>PURITY</th>
                                            <th>GROSS</th>
                                            <th>NET </th>
                                            <th>CT. ST.</th>
                                            <th>RATE</th>
                                            <th>AMOUNT</th>
                                        </tr>
                                        {imitationJewellery.map((item, index) => {
                                            const ornament = ornamentList?.find(
                                                (ornament) => ornament.OrnamentsMasterId == item.Ornament
                                            );
                                            const amount = item.Qty * item.Rate;
                                            totalAmountImitation += amount;
                                            totalQtyImit += parseInt(item.Qty, 10);
                                            totalGrossImit += parseFloat(item.Gross);
                                            totalNetImit += parseFloat(item.Net);
                                            return (
                                                <tr key={index}>
                                                    <td style={{ textAlign: "right" }}>{index + 1}</td>
                                                    <td>{ornament?.OrnamentName}</td>
                                                    <td style={{ textAlign: "right" }}>{item.Qty}</td>
                                                    <td style={{ textAlign: "right" }}>{item.Purity}</td>
                                                    <td style={{ textAlign: "right" }}>{item.Gross}</td>
                                                    <td style={{ textAlign: "right" }}>{item.Net}</td>
                                                    <td style={{ textAlign: "right" }}>{item.CTST}</td>
                                                    <td style={{ textAlign: "right" }}>{item.Rate}</td>
                                                    <td style={{ textAlign: "right" }}>{amount.toFixed(0)}</td>
                                                </tr>
                                            );
                                        })}
                                        <tr>
                                            <td></td>
                                            <td className="note">
                                                <b>
                                                    NOTE:- ALL ABOVE JEWELLERYS ARE NOT PRECIOUS METAL
                                                    <br />
                                                    WE DO NOT FOUND ANY GOLD ,SILVER OR PRECIOUS STONE.
                                                    <br />
                                                    ALL ARE IMITATION JEWELLERY
                                                    <br />
                                                    AS PER PARTY'S INVOICE VALUE RS:{" "}
                                                    {totalAmountImitation.toFixed(0)} /-
                                                </b>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <th></th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>TOTAL</td>
                                            <td style={{ textAlign: "right" }}>{totalQtyImit}</td>
                                            <td></td>
                                            <th style={{ textAlign: "right" }}>{totalGrossImit.toFixed(0)}</th>
                                            <th style={{ textAlign: "right" }}>{totalNetImit.toFixed(0)}</th>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: "right" }}> ₹ {totalAmountImitation.toFixed(0)}</td>
                                        </tr>
                                    </table>
                                </center>
                                <table>
                                    <tr>
                                        <td style={{ width: "20%" }}>In Words:-</td>
                                        <td style={{ width: "100vw" }}>
                                        {toWords.convert(totalAmountImitation, { currency: true }).toUpperCase()}

                                        </td>
                                    </tr>
                                </table>
                                <center>
                                    <table style={{width:"100%"}}>
                                        <tr>
                                            <td>
                                                Valuation based on Market Value as on{" "}
                                                <b>{getDateMonthYear(valuationDate)}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>
                                                    The valuation was done for the jewellery as presented to me
                                                    at the time of valuation. Hence, the responsibility of
                                                    valuation is binded <br />
                                                    for the jewellery presented as on date of valuation
                                                </b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>
                                                    We herby declare that we have no direct or indirect interest
                                                    in the said Jewellery and all the information here are true
                                                    and correct to
                                                    <br /> the best of our knowledge and belief; which are based
                                                    on the facts and information provided by
                                                    Client/Owner/Management.
                                                </b>
                                            </td>
                                        </tr>
                                    </table>
                                </center>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="button-container" style={{ paddingBottom: "8%" }}>
                {/* <button
                    className="savepdfbtn"
                    onClick={handleSubmit}
                    type="primary"
                >
                    Print
                </button> */}
            </div>
            {isLoader && <Loader/>}
        </div >
    );


}
export default GetCertificate;
