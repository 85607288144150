import React, { useState, useEffect } from 'react';
import "../../CSS/banner.css"
import * as yup from "yup";
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import apiRequest from "../../APICall";
import Sidebar from "../SettingsPage/Sidebar";
import { getDBFormateDate, getDBFormateDateForInlineGrid, getDateFormatForDatePicker, getDisplayDate, getMonthDateYear, getdateTimeFormatForPicker } from '../../DateTimeUtils';
import { BiEditAlt, BiTrash } from 'react-icons/bi';
import UploadButtons from '../../Components/Fileupload';
import Placeholder from '../../Assets/images/Placeholder.svg'
import staticImagePath from '../../config.json';
import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  Delete,
  Close,
} from "@material-ui/icons";
import DatePicker from '../../Components/DatePicker';
import MaterialTable from 'material-table';
import { GridIcons, actionColumnStyle, options, style } from '../../Components/GridConfig';
import Loader from '../../Components/Loader';

const BannerAdminPage = () => {

  const currentDate = new Date();
  const [expiryDate, setExpiryDate] = useState(currentDate);
  const [bannerList, setBannerList] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [isTableShown, setIsTableShown] = useState(true);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [base64, setBase64] = useState(null);
  const [file, setFile] = useState(null);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [isImageCanceled, setIsImageCanceled] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [isFileError, setIsFileError] = useState(false);
  const [baseItemImage, setBaseItemImage] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [currentOpr, setCurrentOpr] = useState();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const loggedinId = userData ? userData.ValuerMasterId : "";
  const roleId = userData ? userData.ValuerRoleId : "";

  useEffect(() => {
    bannerGetData();
  }, []);

  const bannerGetData = async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest("GET", `bannergetdata`, null, true, false);
      const modifiedRes = res.map((item, index) => ({
        ...item,
        index: index + 1,
        expiryDate: getDisplayDate(item.ExpiryDate)
      }));
      setIsLoader(false);
      setBannerList(modifiedRes);
    } catch (error) {
      setIsLoader(false);
      toast.error("Try after sometime");
    }
  };

  const initialValues = {
    selectedBannerNo: "",
    hyperlink1: "",
    heading: "",
    paragraph: "",
    expiryDate: currentDate,
  };

  const validationSchema = yup.object({
    selectedBannerNo: yup
      .string()
      .required("Please select Banner Number"),
    hyperlink1: yup.string()
      .required("Please enter hyperlink"),
    heading: yup.string().required("Please enter heading"),
    paragraph: yup.string().required("Please enter paragraph"),
    expiryDate: yup.string().required("Please select expiry date"),
  });


  const { values, handleSubmit, handleChange, errors, resetForm, setValues } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoader(true);
      try {
        if (selectedBanner) {
          if (file !== null && base64 !== null) {
            const type = file.name.split(".")[1];
            const res = await getUploadFileURL(type);
            const fileUploadResult = await fetch(res.url, {
              method: "PUT",
              body: file,
            });
            const { ok = false, status = null } = fileUploadResult;
            if (ok && status == "200") {
              apiRequest("POST", `updatebannerdata`, {
                image: res.Key,
                bannernumber: values.selectedBannerNo,
                hyperlink: values.hyperlink1,
                heading: values.heading,
                paragraph: values.paragraph,
                expiryDate: getDBFormateDate(values.expiryDate),
                loggedinId,
                bannerMasterId: selectedBanner.BannerMasterId
              }, true, false)
                .then((res) => {
                  if (res.status === 200) {
                    toast.success(res.message);
                    bannerGetData();
                    resetForm();
                    setIsShown(false);
                    setSelectedBanner(null);
                    setIsLoader(false);
                    setBase64(null);
                    setFile(null);
                  } else {
                    setIsLoader(false);
                    toast.error(res.message);
                  }
                })
                .catch(function (error) {
                  setIsLoader(false);
                  toast.error("Try after sometime");
                });
            }
          } else {
            apiRequest("POST", `updatebannerdata`, {
              image: "",
              bannernumber: values.selectedBannerNo,
              hyperlink: values.hyperlink1,
              heading: values.heading,
              paragraph: values.paragraph,
              expiryDate: getDBFormateDate(values.expiryDate),
              loggedinId,
              bannerMasterId: selectedBanner.BannerMasterId
            }, true, false)
              .then((res) => {
                if (res.status === 200) {
                  toast.success(res.message);
                  bannerGetData();
                  resetForm();
                  setIsShown(false);
                  setSelectedBanner(null);
                  setBase64(null);
                  setIsLoader(false);
                  setFile(null);
                } else {
                  setIsLoader(false);
                  toast.error(res.message);
                }
              })
              .catch(function (error) {
                setIsLoader(false);
                toast.error("Try after sometime");
              });
          }
        } else {
          if (!file || !base64) {
            setFileUploadError(true);
            setSubmitting(false);
            setIsLoader(false);
            return;
          }
          if (file !== null && base64 !== null) {
            const type = file.name.split(".")[1];
            const res = await getUploadFileURL(type);
            const fileUploadResult = await fetch(res.url, {
              method: "PUT",
              body: file,
            });
            const { ok = false, status = null } = fileUploadResult;
            if (ok && status == "200") {
              apiRequest("POST", `addbannerdata`,
                {
                  image: res.Key,
                  bannernumber: values.selectedBannerNo,
                  hyperlink: values.hyperlink1,
                  heading: values.heading,
                  paragraph: values.paragraph,
                  expiryDate: values.expiryDate,
                  loggedinId
                },
                true, false)
                .then((res) => {
                  if (res.status === 200) {
                    toast.success(res.message);
                    bannerGetData();
                    resetForm();
                    setIsShown(false);
                    setIsLoader(false);
                    setFile(null);
                  } else {
                    setIsLoader(false);
                    toast.error(res.message);
                  }
                })
                .catch(function (error) {
                  setIsLoader(false);
                  toast.error("Try after sometime")
                });
            }
          }
        }
      } catch (error) {
        setIsLoader(false);
        toast.error("Try after sometime")
      }
    }
  });

  const getUploadFileURL = (type) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiRequest("GET", `getAWSFileUploadURL?type=${type}&screen=MyValuation`, null, true, false);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleEdit = (banner) => {
    setSelectedBanner(banner);
    setIsShown(true);
    setBase64(banner.Image);
    setValues({
      selectedBannerNo: banner.BannerNumber,
      hyperlink1: banner.HyperLink,
      heading: banner.Heading,
      paragraph: banner.Paragraph,
      expiryDate: getDisplayDate(banner.expiryDate),
    });
  };

  const columns = [
    {
      title: "Sr.No",
      field: "index"
    },
    {
      title: "BANNER NUMBER",
      field: "BannerNumber",
    },
    {
      title: "Image",
      ...actionColumnStyle,
      render: ({ tableData: { id } }) => (
        <div className="table-edit-controls">
          <img src={bannerList[id].Image} style={{ width: "65%" }} />
        </div>
      ),
      printable: false,
    },
    {
      title: "HYPERLINK",
      field: "HyperLink",
    },
    {
      title: "HEADING",
      field: "Heading",
    },
    {
      title: "PARAGRAPH",
      field: "Paragraph",
    },
    {
      title: "EXPIRY DATE",
      field: "expiryDate",
    },
    {
      title: "CREATED BY",
      field: "ValuerName",
    },
    {
      title: "ACTION",
      ...actionColumnStyle,
      render: ({ tableData: { id } }) => (
        <div className="table-edit-controls">
          <Tooltip title="Click to edit" style={{ cursor: "pointer" }}>
            <BiEditAlt onClick={() => handleEdit(bannerList[id])} />
          </Tooltip>
        </div>
      ),
      printable: false,
    },
  ]

  return (
    <>
      <div className="settingsmaindiv">
        <section className="set-secBs">
          <div className='banner-container'>
            <div className='isInline'>
            <h1 style={{ display:"flex" ,textAlign: "center", alignItems:"center", paddingLeft:"1%" }}><Sidebar />Banner</h1>
              <button className="add-ornament-btn"
                onClick={() => {
                  setIsShown(true);
                  resetForm();
                  setExpiryDate(currentDate)
                }}>
                Add Banner
              </button>
            </div><Divider /><br />
            {!isShown && isTableShown && (
              <section className="tablecontainer">
                <MaterialTable
                  icons={GridIcons}
                  title={`Banner List`}
                  columns={columns}
                  data={bannerList}
                  options={options}
                  style={style}
                />
              </section>
            )}

            {isShown && (
              <form onSubmit={handleSubmit} style={{ border: "none" }}>
                <div className="banner-card">
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={4}>
                      <UploadButtons
                        setIsFileError={setIsFileError}
                        setFileUploadError={setFileUploadError}
                        isFileCanceled={isImageCanceled}
                        setIsImageCanceled={setIsImageCanceled}
                        setBase64={setBase64}
                        setFile={setFile}
                        accept={"image/*"}
                        size={500}
                        error={isFileError}
                        errorMessage={"Item image is required."}
                      />

                      {base64 == null ? (
                        <img
                          src={Placeholder}
                          className="img-fluid"
                          width="120"
                          height="120"
                          alt="Item"
                          style={{
                            marginTop: "14px",
                          }}
                        />
                      ) : (
                        <div style={{ display: "flex", gap: "3%" }}>
                          <span>
                            <img
                              src={base64}
                              className="img-fluid"
                              width="120"
                              height="120"
                              alt="Item"
                              style={{
                                marginTop: "14px",
                              }}
                            />
                          </span>
                          <span />

                          {base64 !== staticImagePath && (
                            <span>
                              <Tooltip title="Cancel">
                                <IconButton
                                  aria-label="cancel"
                                  onClick={() => {
                                    setIsImageCanceled(true);
                                    setBase64(baseItemImage);
                                    setFile(null);
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </span>
                          )}
                          {baseItemImage !== staticImagePath && currentOpr === "Update" && (
                            <span>
                              <Tooltip title="Delete">
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    setIsImageDeleted(true);
                                    setBase64(null);
                                    setFile(null);
                                  }}
                                >
                                  <Delete fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>Select Banner: *</label>
                      <select
                        id="selectedBannerNo"
                        name="selectedBannerNo"
                        className="inputFieldGrid"
                        value={values.selectedBannerNo}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>
                          Select Banner
                        </option>
                        <option value="Banner 1">Banner 1</option>
                        <option value="Banner 2">Banner 2</option>
                        <option value="Banner 3">Banner 3</option>
                      </select>
                      {errors.selectedBannerNo && (<div className="errorcss">{errors.selectedBannerNo}</div>)}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>Hyperlink: *</label>
                      <input
                        type="text"
                        name="hyperlink1"
                        id="hyperlink1"
                        placeholder='Hyperlink'
                        className="inputFieldGrid"
                        value={values.hyperlink1}
                        onChange={handleChange}
                      />
                      {errors.hyperlink1 && (<div className="errorcss">{errors.hyperlink1}</div>)}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>Heading:</label>
                      <input
                        type="text"
                        name="heading"
                        placeholder='Heading'
                        className="inputFieldGrid"
                        id="heading"
                        value={values.heading}
                        onChange={handleChange}
                      />
                      {errors.heading && (<div className="errorcss">{errors.heading}</div>)}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>Paragraph:</label>
                      <input
                        id="paragraph"
                        name="paragraph"
                        placeholder='Paragraph'
                        className="inputFieldGrid"
                        value={values.paragraph}
                        onChange={handleChange}
                      ></input>
                      {errors.paragraph && (<div className="errorcss">{errors.paragraph}</div>)}

                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>Expiry Date:</label>
                      <DatePicker
                        id="expiryDate"
                        label={"Expiry Date"}
                        defaultValue={values.expiryDate}
                        onChange={(e) => {
                          handleChange({ target: { name: "expiryDate", value: e } });
                        }}
                      />
                      {errors.expiryDate && (<div className="errorcss">{errors.expiryDate}</div>)}
                    </Grid>
                  </Grid>

                  <div className='isInline-1'>
                    <button onClick={() => {
                      setIsShown(false);
                      setSelectedBanner(null);
                      setBase64(null);
                    }}>
                      Cancel
                    </button>
                    <button type="submit" >
                      {selectedBanner ? "Update Banner" : "Add Banner"}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </section>
      </div>
      {isLoader && <Loader/>}
    </>
  );
};

export default BannerAdminPage;
