import { ThemeProvider } from 'styled-components';
import './App.css';
import Index from './Navigation/index';
import theme from './Components/Theme';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <Index />
      </header>
    </div>
  );
}

export default App;