import React, { useEffect, useState } from "react";
import "../../CSS/header.css";
import "../../CSS/global.css";
import { useFetcher, useNavigate } from "react-router-dom";
import { Pane, Dialog } from "evergreen-ui";
import { FaUserAlt } from "react-icons/fa";
import { BsQrCodeScan } from "react-icons/bs";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { GoTriangleDown } from "react-icons/go";
import apiRequest from "../../APICall";
import Loader from "../../Components/Loader";

function Header(isLoggedIn) {
  const [isShownPop, setIsShownPop] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const [jewellerName, setJewellerName] = useState("");
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  const loggedinId = userData ? userData.ValuerMasterId : "";
  const roleId = userData ? userData.ValuerRoleId : "";
  const jewellerId = userData ? userData.JewellerId : "";

  // function to get jeweller data
  const getJewellerData = async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest(
        "GET",
        `getJewellerDataById?jewellerId=${jewellerId}`,
        null,
        true,
        false
      );
      setJewellerName(res[0].JewellerName)
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      toast.error("Try after sometime");
    }
  };

  useEffect(() => {
    if (roleId !== 1 && jewellerId) {
      getJewellerData();
    }
  }, [jewellerId]);

  const handleLogout = () => {
    toast.success("Logout Successfully!");
    localStorage.removeItem("userData");
    localStorage.removeItem("userToken");
    localStorage.clear();
    window.location.href = '/';
  };

  const removeData = () => {
    localStorage.removeItem("CustDetails");
    localStorage.removeItem("tableData");
    localStorage.removeItem("CertificateNumber");
    localStorage.removeItem("Updated");
  };
  console.log(roleId)

  return (
    <>
      <div className="topbar">
        <div>
          <Link to="/">
            <h3 className="headertext">My</h3>
            <h3 className="headertext">Valuation</h3>
          </Link>
        </div>
        <div className="ad-banner-header">

        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          &ensp;&ensp;
          {localStorage.getItem("userToken") === null ? (
            <div style={{ display: "flex", width: "100%", alignItems:"center" }}>
            <Link to="/Signup">
              <button className="Resgisterheadline">Register For Free</button></Link>
              <FaUserAlt
                onClick={() => {
                  navigate("/login");
                }}
                style={{ fontSize: "2rem", color: "#8b2020", cursor: "pointer" }}
              /></div>) : (
            <div>
              <h3
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  margin: "0",
                }}
                onClick={() => {
                  setIsShownPop(!isShownPop);
                }}
              >
                {userData?.ValuerName}
                <GoTriangleDown />
              </h3>
              <p style={{ fontSize: "0.9rem", color: "var(--font)", cursor: "pointer" }} onClick={() => {
                setIsShownPop(!isShownPop);
              }}>
                {jewellerName}
              </p>
            </div>
          )}
        </div>
      </div>
      {isLoggedIn && isShownPop && (
        <Pane
          className="popup1"
          isShown={isShownPop}
          hasHeader={false}
          hasFooter={false}
          onCloseComplete={() => setIsShownPop(false)} // Added onCloseComplete to handle closing
        >
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <i
                class="fa-solid fa-xmark close-popup"
                onClick={() => setIsShownPop(false)}
              />
            </div>

            <div
              style={{
                padding: "5%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <p className="text">
                <Link
                  className="popup-link"
                  to="/ValuerProfile"
                  onClick={() => setIsShownPop(false)}
                >
                  - Profile
                </Link>
              </p>
              {roleId && roleId !== 3 && roleId !== 5 && (
                <p className="text">
                  <Link
                    className="popup-link"
                    to="/valuer"
                    onClick={() => setIsShownPop(false)}
                  >
                    - Settings
                  </Link>
                </p>
              )}
              { roleId !==5 && (
                  <>
              <p className="text">
                <Link
                  className="popup-link"
                  to="/valuationsteps"
                  onClick={() => {
                    setIsShownPop(false);
                    removeData();
                  }}
                >
                  - Valuation
                </Link>
              </p>
              <p className="text">
                <Link
                  className="popup-link"
                  to="/valuationhistory"
                  onClick={() => {
                    setIsShownPop(false);
                    removeData();
                  }}
                >
                  - Valuation History
                </Link>
              </p>
          </>)}
              {roleId && (roleId == 1 || roleId ==5) && (
                
                   <p className="text">
                <Link
                  className="popup-link"
                  to="/blogs"
                  onClick={() => setIsShownPop(false)}
                >
                -  Blogs
                </Link>
              </p>
             
              )}
             
           
              {/* <p className="text">
                <Link
                  className="popup-link"
                  to="/Changepasswordpage"
                  onClick={() => setIsShownPop(false)}
                >
                  - Change Password
                </Link>
              </p> */}
              <p
                style={{ cursor: "pointer" }}
                className="popup-link"
                onClick={handleLogout}
              >
                - Logout
              </p>
            </div>
          </>
        </Pane>
      )}
      {isLoader && <Loader />}
    </>
  );
}
export default Header;
