import React from "react";
import '../../CSS/footer.css';
import '../../CSS/global.css';
import { Link } from "react-router-dom/dist";




function Footer(){
    return(
        <div className="footer_div">
            <p className="footertext">© 2024 Jewellery Valuation. All Rights Reserved.</p>
            <p className="termpolicy-text">
            <Link to="/privacypolicy" className="termpolicy">Terms & Conditions | Privacy Policy</Link></p>
        </div>
    )
}
export default Footer;