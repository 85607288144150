import React, { useEffect, useState } from "react";
import "../CSS/Blog.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import apiRequest from "../APICall";
import MediaPicker from "../Components/MediaPicker";
import TextEditor from "../Components/TextEditor";
import toast from "react-hot-toast";
const { getImagePath } = require("../config.json");

export default function Blogs() {
  const [data, setData] = useState([]);

  const getApiData = async () => {
    return new Promise((resolve, reject) => {
      apiRequest("GET", "blogs/getblog", null, true, true)
        .then((res) => {
          sessionStorage.setItem("blogApi", JSON.stringify(res));
          resolve(res);
          setData(res.data);
        })
        .catch((error) => {
          console.error("Error calling Blog API:", error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    getApiData();
  }, []);
  return (
    <div className="blog_data_container">
      <h1>Blog Posts</h1>
      <button className="add_blog">
        <Link to="/blogs/addBlog" className="add-blog-post">
          + Add blog post
        </Link>
      </button>
      <div className="blog-posts-list">
                {data.map((item) => (
                    <Link key={item.BlogId} className="blog-post-card" to={`/blogs/editBlog/${item.BlogId}`}>
                        <div className="blog-post-image">
                            <img src={getImagePath.ImagePath + item.FeatureImage} alt={item.Title} />
                        </div>
                        <section className="blog-post-content">
                            <h3 className="blog-post-title">{item.Title}</h3>
                            <p className="blog-post-description">{item.Description.length > 80
                        ? item.Description.slice(0, 80) + '...' 
                        : item.Description}</p>
                            {/* Uncomment if needed */}
                            {/* <p className="blog-post-author">
                                <strong>Created By:</strong> {item.adminFirstName} {item.adminLastName}
                            </p> */}
                            <span className="blog-post-status">Status: {item.Status}</span>
                        </section>
                    </Link>
                ))}
            </div>
            <br/>
            <br/>
    </div>
  );
}

export function AddBlogPost() {
  const navigate = useNavigate();
  const { postId } = useParams();

  const [data, setData] = useState({
    featureImage: { image: "", file: "", status: "none" },
    title: "",
    description: "",
    permalink: "",
    content: "",
    author: "",
  });
  const [buttonText, setButtonText] = useState("Save to draft");
  const [isLoader, setIsLoader] = useState(false);

  const _data = data;

  const getBlogPost = async () => {
    if (postId) {
      const res = await apiRequest(
        "GET",
        "blog-post/" + postId,
        null,
        true,
        true
      );
      console.log(res)
      if (res) {
        _data.title = res.Title;
        _data.description = res.Description;
        _data.author = res.Author;
        _data.permalink = res.Permalink;
        _data.content = res.Content;
        _data.featureImage.image = getImagePath.ImagePath + res.FeatureImage;
        _data.featureImage.imageName = res.FeatureImage;
        updateData();
        if (res.status === "published") {
          setButtonText("Revert to draft");
        }
      }
    }
  };

  useEffect(() => {
    getBlogPost();
  }, []);

  const updateData = () => {
    setData((data) => ({ ...data, ..._data }));
  };

  const getUploadFileURL = async (type) => {
    try {
      const res = await apiRequest(
        "GET",
        `getAWSFileUploadURL?type=${type}&screen=MyValuation`,
        null,
        true,
        false
      );
      return res.data;
    } catch (error) {
      throw new Error("Failed to get upload URL.");
    }
  };
  const handleSave = async (status) => {
    if (data.title && data.content && data.author && data.featureImage.image) {
      
        console.log(data.featureImage.status)
     
      setIsLoader(true);
      try {
        let imageUrl;
   

        if (data.featureImage.status === "updated") {
          const type = data.featureImage.file.name.split(".")[1];
          const uploadData = await getUploadFileURL(type);
          const fileUploadResult = await fetch(uploadData.url, {
            method: "PUT",
            body: data.featureImage.file,
          });

          if (fileUploadResult.ok) {
            imageUrl = uploadData.Key;
          } else {
            throw new Error("Failed to upload the image.");
          }
        } else {
          imageUrl = data.featureImage.imageName;
        }

        await saveData(imageUrl, status);
      } catch (error) {
        toast.error(error.message || "An error occurred while saving.");
      } finally {
        setIsLoader(false);
      }
    } else {
      toast.error("Please fill all values.");
    }
  };

  const saveData = async (imageUrl, status) => {
    const params = {
      content: data.content,
      title: data.title,
      description: data.description,
      author: data.author,
      featureImage: imageUrl,
      permalink: data.permalink,
      status,
    };

    try {
      let res;
      if (!postId) {
        res = await apiRequest("POST", "blog/addBlog", params, true, true);
      } else {
        res = await apiRequest(
          "POST",
          `blog/editBlog/${postId}`,
          params,
          false,
          false
        );
      }

      if (res) {
        toast.success("Data saved successfully");
        navigate( "/blogs" );
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while saving.");
    }
  };

  return (
    <div>
      <div className="blog-start">
        <h2>Add blog post</h2>
        <main>
          <div className="top">
            <div className="btns">
              <button className="btn" onClick={() => handleSave("draft")}>
                {buttonText}
              </button>
              <button className="btn" onClick={() => handleSave("published")}>
                Publish
              </button>
              <button className="btn" onClick={() => navigate("/blogs")}>
                Cancel
              </button>
            </div>
          </div>
          <section>
            <TextEditor
              onChange={(text) => {
                _data.content = text;
                updateData();
              }}
              value={data.content}
            />
            <div className="right-side">
              <div className="sec add-blog">
                <div className="input-field">
                  <input
                    id="title"
                    type="text"
                    className="validate"
                    onChange={(text) => {
                      _data.title = text.currentTarget.value;
                      updateData();
                    }}
                    value={data.title}
                  />
                  <label htmlFor="title">Title</label>
                </div>
              </div>
              <div className="sec add-blog">
                <div className="input-field">
                  <textarea
                    id="description"
                    className="materialize-textarea"
                    style={{ height: 100 }}
                    onChange={(text) => {
                      _data.description = text.currentTarget.value;
                      updateData();
                    }}
                    value={data.description}
                  />
                  <label htmlFor="description">Description</label>
                </div>
              </div>
              <div className="sec add-blog">
                <div className="input-field">
                  <input
                    id="permalink"
                    type="text"
                    className="validate"
                    onChange={(text) => {
                      _data.permalink = text.currentTarget.value.replaceAll(
                        " ",
                        "-"
                      );
                      updateData();
                    }}
                    value={data.permalink}
                  />
                  <label htmlFor="permalink">Permalink</label>
                </div>
              </div>
              <div className="sec add-blog">
                <div className="input-field">
                  <input
                    id="author"
                    type="text"
                    className="validate"
                    onChange={(text) => {
                      _data.author = text.currentTarget.value;
                      updateData();
                    }}
                    value={data.author}
                  />
                  <label htmlFor="author">Author</label>
                </div>
              </div>
              <div className="sec add-blog">
                <MediaPicker
                  text="Feature image"
                  onChange={(file, image) => {
                    _data.featureImage = { file, image, status: "updated" };
                    updateData();
                  }}
                  type="image"
                  source={data.featureImage.image}
                />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
