import React, { useState, useEffect } from 'react';
import "../../CSS/banner.css"
import * as yup from "yup";
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import apiRequest from "../../APICall";
import Sidebar from "../SettingsPage/Sidebar";
import { getDisplayDate, getMonthDateYear } from '../../DateTimeUtils';
import { BiEditAlt, BiTrash } from 'react-icons/bi';
import {
    Divider,
    Grid,
    Tooltip,
    fade,
} from "@material-ui/core";
import MaterialTable from 'material-table';
import { GridIcons, actionColumnStyle, options, style } from '../../Components/GridConfig';
import Loader from '../../Components/Loader';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ManageValuerPage = () => {

    const [valuerList, setValuerList] = useState([]);
    const [isShown, setIsShown] = useState(false);
    const [isTableShown, setIsTableShown] = useState(true);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [isLoader, setIsLoader] = useState(true);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const loggedinId = userData ? userData.ValuerMasterId : "";
    const roleId = userData ? userData.ValuerRoleId : "";
    const jewellerId = userData ? userData.JewellerId : "";

    useEffect(() => {
        valuerGetData();
    }, []);

    const valuerGetData = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("GET", `valuergetdata?loggedinId=${loggedinId}&roleId=${roleId}`,
                false,
                true, false);
            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
            }));
            setIsLoader(false);
            setValuerList(modifiedRes);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const initialValues = {
        valuerName: "",
        mobile: "",
        password: ""
    };

    const validationSchema = yup.object({
        valuerName: yup
            .string()
            .required("Please enter valuer name"),
        mobile: yup.string()
            .required("Please enter Mobile Number")
            .min(10, "Mobile Number should be 10 digit.")
            .max(10, "Mobile Number should be 10 digit.")
            .matches(phoneRegExp, "Phone number is not valid"),
        password: yup.string()
            .required("Please enter password")
            .min(8, "Password should be greater than 8 characters.")
            .max(15, "Password should be less than 15 characters.")
    });

    const { values, handleSubmit, handleChange, errors, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setIsLoader(true);
            try {
                if (selectedBanner) {
                    apiRequest("POST", `updatevaluerdata`,
                        {
                            valuerName: values.valuerName,
                            mobile: values.mobile,
                            password: values.password,
                            valuerMasterId: valuerList[selectedBanner].ValuerMasterId,
                            loggedinId
                        },
                        true, false)
                        .then((res) => {
                            if (res.status === 200) {
                                toast.success(res.message);
                                valuerGetData();
                                resetForm();
                                setIsShown(false);
                                setIsLoader(false);
                                setSelectedBanner(null);
                            } else {
                                setIsLoader(false);
                                toast.error(res.message);
                            }
                        })
                        .catch(function (error) {
                            setIsLoader(false);
                            toast.error("Try after sometime")
                        });
                } else {
                    apiRequest("POST", `addvaluerdata`,
                        {
                            valuerName: values.valuerName,
                            mobile: values.mobile,
                            password: values.password,
                            loggedinId,
                            jewellerId
                        },
                        true, false)
                        .then((res) => {
                            if (res.status === 200) {
                                toast.success(res.message);
                                valuerGetData();
                                resetForm();
                                setIsShown(false);
                                setIsLoader(false);
                            } else if (res.status === 400) {
                                toast.error(res.message);
                                setIsLoader(false);
                            } else {
                                toast.error(res.message);
                                setIsLoader(false);
                            }
                        })
                        .catch(function (error) {
                            setIsLoader(false);
                            toast.error("Try after sometime")
                        });
                }
            } catch (error) {
                setIsLoader(false);
                toast.error("Try after sometime")
            }
        }
    });

    const handleEdit = (id) => {
        setSelectedBanner(id);
        setIsShown(true);
        setValues({
            valuerName: valuerList[id].ValuerName,
            mobile: valuerList[id].MobileNumber,
            password: valuerList[id].Password
        });
    };
    const columns = [
        {
            title: "Sr.No",
            field: "index"
        },
        {
            title: "Valuer Name",
            field: "ValuerName",
        },
        {
            title: "Mobile Number",
            field: "MobileNumber",
        },
        {
            title: "Password",
            field: "Password",
        },
        {
            title: "Admin",
            field: "SuperAdmin",
        },
        ...(roleId !== 1 ? [
            {
                title: "Action",
                ...actionColumnStyle,
                render: ({ tableData: { id } }) => (
                    <div className="table-edit-controls">
                        <Tooltip title="Edit">
                            <BiEditAlt onClick={() => handleEdit(id)} />
                        </Tooltip>
                    </div>
                ),
                printable: false,
            }
        ] : [])
    ]

    return (
        <>
            <div className="settingsmaindiv">
                <section className="set-secBs">
                    <div className='banner-container'>
                        <div className='isInline'>
                            <h1 style={{ display:"flex" , alignItems:"center", paddingLeft:"1%" }}>
                        <Sidebar />&nbsp;Valuer</h1>
                            <button className="add-ornament-btn"
                                onClick={() => {
                                    setIsShown(true);
                                    resetForm();
                                }} disabled={roleId === 1}>
                                Add Valuer
                            </button>
                        </div>
                        <Divider />
                        {!isShown && isTableShown && (
                            <section className="tablecontainer">
                                <MaterialTable
                                    icons={GridIcons}
                                    title={`List of valuers`}
                                    columns={columns}
                                    data={valuerList}
                                    options={options}
                                    style={style}
                                />
                            </section>
                        )}

                        {isShown && (
                            <form onSubmit={handleSubmit} style={{ border: "none" }}>
                                <div className="banner-card">
                                    <Grid container spacing={3} style={{ alignItems: "center" }}>
                                        <Grid item xs={12} md={4}>
                                            <label>Valuer Name: *</label>
                                            <input
                                                type="text"
                                                name="valuerName"
                                                id="valuerName"
                                                className='inputFieldGrid'
                                                value={values.valuerName}
                                                onChange={handleChange}
                                                placeholder='Valuer Name'
                                                maxLength={30}
                                            />
                                            {errors.valuerName && (<div className="errorcss">{errors.valuerName}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label>Mobile Number: *</label>
                                            <input
                                                type="text"
                                                name="mobile"
                                                id="mobile"
                                                className='inputFieldGrid'
                                                placeholder='Mobile Number'
                                                value={values.mobile}
                                                onChange={handleChange}
                                                maxLength={10}
                                            />
                                            {errors.mobile && (<div className="errorcss">{errors.mobile}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label>Password: *</label>
                                            <input
                                                type="text"
                                                name="password"
                                                id="password"
                                                placeholder='Password'
                                                className='inputFieldGrid'
                                                value={values.password}
                                                onChange={handleChange}
                                                maxLength={30}
                                            />
                                            {errors.password && (<div className="errorcss">{errors.password}</div>)}
                                        </Grid>
                                    </Grid>
                                    <div className='isInline-1'>
                                        <button onClick={() => {
                                            setIsShown(false);
                                            setSelectedBanner(null); // Clear selected banner if cancel is clicked
                                        }}>
                                            Cancel
                                        </button>
                                        <button type="submit" >
                                            {selectedBanner ? "Update Valuer" : "Add Valuer"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </section>
            </div>
            {isLoader && <Loader />}
        </>
    );
};

export default ManageValuerPage;
