import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Loader = () => {
    return (
        <div className='loader-container'>
            <CircularProgress color="#832729" />
        </div>
    );
}

export default Loader;
