import React from "react";
import "../CSS/privacypolicy.css"

const Privacy = () => {
    return (
      <div className="privacy-start">
        <h1>Privacy Policy</h1>
        <p>Last updated: 09/09/2024</p>
        <br/>
        <p>
          We are committed to protecting your personal information and your right to privacy. 
          If you have any questions or concerns about our policy, or our practices with regards to your personal information, 
          please contact us at <a href="mailto:info@myvaluation.online">info@myvaluation.online</a>.
        </p>
        
        <p>
          When you visit our site and use our services, you trust us with your personal information. 
          We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible 
          what information we collect, how we use it, and what rights you have in relation to it. 
          We hope you take some time to read through it carefully, as it is important. 
          If there are any terms in this privacy policy that you do not agree with, please discontinue use of our site and our services.
        </p>
  
        <p>
          This privacy policy applies to all information collected through our site and/or any related services, sales, marketing, or events 
          (we refer to them collectively in this privacy policy as the “Services”).
        </p>
  
        <p>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</p>
  
        <h2>Table of Contents</h2>
        <ul>
          <li>What Information Do We Collect?</li>
          <li>Will Your Information Be Shared With Anyone?</li>
          <li>Do We Use Cookies and Other Tracking Technologies?</li>
          <li>How Do We Handle Your Social Logins?</li>
          <li>Is Your Information Transferred Internationally?</li>
          <li>What Is Our Stance on Third-Party Websites?</li>
          <li>How Long Do We Keep Your Information?</li>
          <li>Do We Collect Information From Minors?</li>
          <li>What Are Your Privacy Rights?</li>
          <li>Controls for Do-Not-Track Features</li>
          {/* <li>Do California Residents Have Specific Privacy Rights?</li> */}
          <li>Do We Make Updates to This Policy?</li>
          <li>How Can You Contact Us About This Policy?</li>
        </ul>
  
        <h2>What Information Do We Collect?</h2>
        <h3>Personal Information You Disclose to Us</h3>
        <p>
          We collect personal information that you provide to us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us when registering or expressing an interest in obtaining 
          information about us or our products and services, when participating in activities on the site (such as posting messages in our online forums 
          or entering competitions, contests, or giveaways) or otherwise contacting us.
        </p>
        <p>
          The personal information that we collect depends on the context of your interactions with us and the Services, 
          the choices you make, and the products and features you use.
        </p>
  
        <h3>Information Automatically Collected</h3>
        <p>
          Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our site.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or navigate the site. 
          This information does not reveal your specific identity but may include device and usage information, 
          such as your IP address, browser and device characteristics, operating system, language preferences, 
          referring URLs, device name, country, location, information about how and when you use our Services, and other technical information.
        </p>
  
        <h2>Will Your Information Be Shared With Anyone?</h2>
        <p>
          We only share information with your consent, to comply with laws, to provide you with services, 
          to protect your rights, or to fulfill business obligations.
        </p>
        <p>
          We may process or share data based on the following legal basis:
        </p>
        <ul>
          <li><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
          <li><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
          <li><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
          <li><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so to comply with applicable law, governmental requests, or legal processes.</li>
          <li><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies or illegal activities.</li>
        </ul>
        <p>
          More specifically, we may need to process your data or share your personal information in the following situations:
        </p>
        <ul>
          <li><strong>Vendors, Consultants, and Other Third-Party Service Providers:</strong> We may share your data with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf.</li>
          <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with any merger, sale of company assets, financing, or acquisition of our business.</li>
          <li><strong>Third-Party Advertisers:</strong> We may use third-party advertising companies to serve ads when you visit our site.</li>
          <li><strong>Affiliates:</strong> We may share your information with our affiliates, requiring them to honor this privacy policy.</li>
          <li><strong>Business Partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
          <li><strong>Other Users:</strong> When you share personal information or otherwise interact with public areas of the site, such personal information may be viewed by all users.</li>
        </ul>
  
        <h2>Do We Use Cookies and Other Tracking Technologies?</h2>
        <p>
        We may use cookies and other tracking technologies to collect and store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. 
          Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.
        </p>
  
        <h2>How Do We Handle Your Social Logins?</h2>
        <p>
         If you choose to register or log in to our services using a social media account, we may have access to certain information about you.
        </p>
        <p>
          We offer you the ability to register and login using your third-party social media account details. 
          The profile information we receive may vary depending on the social media provider concerned, but will often include your name, 
          email address, friends list, and profile picture.
        </p>
  
        <h2>Is Your Information Transferred Internationally?</h2>
        <p>
          We may transfer, store, and process your information in countries other than your own.
        </p>
        <p>
          Our servers are located in [insert location]. If you are accessing our site from outside, your information may be transferred to, 
          stored, and processed by us in our facilities and by those third parties with whom we may share your personal information.
        </p>
  
        <h2>What Is Our Stance on Third-Party Websites?</h2>
        <p>
         We are not responsible for the safety of any information that you share with third-party providers.
        </p>
        <p>
          Our site may contain advertisements from third parties not affiliated with us. We cannot guarantee the safety and privacy of data 
          you provide to any third parties.
        </p>
  
        <h2>How Long Do We Keep Your Information?</h2>
        <p>
           We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy.
        </p>
  
        <h2>Do We Collect Information From Minors?</h2>
        <p>
          We do not knowingly collect data from or market to children under 18 years of age.
        </p>
        <p>
          By using the site, you represent that you are at least 18 or that you are the parent or guardian of a minor and consent to such minor’s use of the site.
        </p>
  
        <h2>What Are Your Privacy Rights?</h2>
        <p>
          You may review, change, or terminate your account at any time.
        </p>
        <p>
          If you are a resident in the European Economic Area and believe we are unlawfully processing your personal information, 
          you have the right to complain to your local data protection supervisory authority.
        </p>
  
        <h2>Controls for Do-Not-Track Features</h2>
        <p>
          Most web browsers include a Do-Not-Track (“DNT”) feature you can activate to signal your preference not to have data monitored and collected.
        </p>
  
        <h2>Do California Residents Have Specific Privacy Rights?</h2>
        <p>
        Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
        </p>
        <p>
          Under California Civil Code Section 1798.83, California residents can request information about categories of personal information we disclosed 
          to third parties for direct marketing purposes.
        </p>
  
        <h2>Do We Make Updates to This Policy?</h2>
        <p>
          Yes, we will update this policy as necessary to stay compliant with relevant laws.
        </p>
        <p>
          We may update this privacy policy from time to time. The updated version will be effective as soon as it is accessible.
        </p>
  
        <h2>How Can You Contact Us About This Policy?</h2>
        <p>
          If you have questions or comments about this policy, you may email us at <a href="mailto:info@myvaluation.online">info@myvaluation.online</a>.
        </p>
  
        <h2>How Can You Review, Update, or Delete the Data We Collect From You?</h2>
        <p className="policy-text">
          Based on the laws of some countries, you may have the right to request access to, change, or delete the personal information we collect from you. 
          To make such a request, please submit a request at <a href="mailto:info@valuation.online">info@valuation.online</a>. 
          We will respond to your request within 30 days.
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
       
      </div>
    );
  };
  
  export default Privacy;
  