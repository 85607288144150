import React, { useEffect, useState } from 'react';
import "../../CSS/banner.css";
import Sidebar from "../SettingsPage/Sidebar";
import {
    Grid,
    Tooltip,
} from "@material-ui/core";
import SelectControl from '../../Components/Select';
import DatePicker from '../../Components/DatePicker';
import apiRequest from '../../APICall';
import toast from 'react-hot-toast';
import { getDBFormateDate, getDisplayDate } from '../../DateTimeUtils';
import { BiEditAlt, BiSolidDownload } from "react-icons/bi";
import MySelect from '../../Components/AutoComplete';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';
import { GridIcons, actionColumnStyle, options, style } from '../../Components/GridConfig';
import Divider from '@mui/material/Divider';
import Loader from '../../Components/Loader';

const ValuationHistory = () => {

    const navigate = useNavigate();

    // const currentdate = new Date().toISOString().slice(0, 10);
    const currentdate = new Date();
    const [isShown, setIsShown] = useState(false);
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [effectiveDate, setEffectiveDate] = useState(currentdate);
    const [selectedValuer, setSelectedValuer] = useState("");
    const [valuerList, setValuerList] = useState([]);
    const [valuationList, setValuationList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const loggedinId = userData ? userData.ValuerMasterId : "";
    const roleId = userData ? userData.ValuerRoleId : "";
    const jewellerId = userData ? userData.JewellerId : "";

    useEffect(() => {
        getCustomerList();
        getValuerList();
        getValuationdetails();
    }, []);

    const getCustomerList = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("GET", `customerdetailsget`, null, true, false);
            const transformedData = res.map(item => ({
                value: item.value,
                label: item.name
            }));
            setIsLoader(false);
            setCustomerList(transformedData);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };
    const getValuerList = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("GET", `valuerdetailsget?loggedinId=${loggedinId}&roleId=${roleId}`, null, true, false);
            const transformedData = res.map(item => ({
                value: item.value,
                label: item.name
            }));
            setValuerList(transformedData);
            setIsLoader(false);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const getValuationdetails = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("post", `getValuationdetails`, {
                selectedCustomer,
                date: effectiveDate !== null ? getDBFormateDate(effectiveDate) : null,
                selectedValuer,
                roleId,
                loggedinId,
                jewellerId
            }, true, false);
            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
                DateOfValuation: getDisplayDate(item.DateOfValuation)
            }));
            setIsShown(true);
            setValuationList(modifiedRes);
            setIsLoader(false);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const getCertificateDataById = async (valuationData) => {
        setIsLoader(true);
        try {
            const res = await apiRequest(
                "GET",
                `getCertificateDataById?certificateNumber=${valuationData?.CertificateNumber}`,
                null,
                true,
                false
            );

            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
                ValuationReport: JSON.parse(item.ValuationReport),
            }));
            setIsLoader(false);
            return (modifiedRes);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const handleClick = (valuation) => {
        navigate('/generateCertificate', { state: { valuationData: valuation } });
    };

    const handleUpdate = async (valuation) => {
        const response = await getCertificateDataById(valuation);
        const values = {
            selectedCustomer: response[0]?.selectedCustomer,
            selectedInvoiceNumber: response[0]?.InvoiceNumber,
            selectedPurpose: response[0]?.PurposeOfValuation,
            selectedawbNumber: response[0]?.AWBNumber,
            selectedDate: response[0]?.DateOfValuation
        }
        localStorage.setItem("CustDetails", JSON.stringify(values));
        localStorage.setItem("Updated", (true));
        localStorage.setItem("CertificateNumber", (valuation.CertificateNumber))
        localStorage.setItem("tableData", JSON.stringify(response[0].ValuationReport));
        navigate('/valuationsteps', { state: { valuationData: valuation } });
    };

    useEffect(() => {
        const beforeUnloadHandler = () => {
            localStorage.removeItem("CustDetails");
            localStorage.removeItem("tableData");
            localStorage.removeItem("CertificateNumber");
            localStorage.removeItem("Updated");
        };
        window.addEventListener("beforeunload", beforeUnloadHandler);
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
    }, []);

    const columns = [
        {
            title: "Sr.No",
            field: "index"
        },
        {
            title: "Customer Name",
            field: "CustomerName",
        },
        {
            title: "Jeweller Name",
            field: "JewellerName",
        },
        {
            title: "Valuer",
            field: "ValuerName",
        },
        {
            title: "Certificate Number",
            field: "CertificateNumber",
        },
        {
            title: "Date Of Valuation",
            field: "DateOfValuation",
        },
        {
            title: "Purpose Of Valuation",
            field: "PurposeOfValuationName",
        },
        ...(roleId !== 3 ? [
            {
                title: "Status",
                ...actionColumnStyle,
                render: ({ tableData: { id } }) => (
                    <div className="table-edit-controls">
                        {valuationList[id].Status === "Submitted" ? (
                            <Tooltip title="Click to View">
                                <p style={{ cursor: "pointer" }} onClick={() => handleClick(valuationList[id])}>{valuationList[id].Status}</p>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Click to Edit">
                                <p
                                    style={{ cursor: "pointer" }} onClick={() => handleUpdate(valuationList[id])}>
                                    {valuationList[id].Status}
                                </p>
                            </Tooltip>
                        )}
                    </div>
                ),
                printable: false,
            },
        ] : []),
        ...(roleId !== 3 ? [
            {
                title: "Download",
                ...actionColumnStyle,
                render: ({ tableData: { id } }) => (
                    <div className="table-edit-controls">
                        {valuationList[id].Status === "Submitted" && (
                            <Tooltip title="Download">
                                <a href={valuationList[id].Link} target="_blank" rel="noopener noreferrer" style={{ color: "var(--font)", cursor: "pointer" }}>
                                    <BiSolidDownload style={{ cursor: "pointer" }} />
                                </a>
                            </Tooltip>
                        )}
                    </div>
                ),
                printable: false,
            }
        ] : []),
        ...(roleId === 3 ? [
            {
                title: "Status",
                ...actionColumnStyle,
                render: ({ tableData: { id } }) => (
                    <div className="table-edit-controls">
                        <Tooltip title="Click to View">
                            <p style={{ cursor: "pointer" }} onClick={() => handleClick(valuationList[id])}>{valuationList[id].Status}</p>
                        </Tooltip>
                    </div>
                ),
                printable: false,
            },
        ] : []),
    ]

    return (
        <>
            <div className="settingsmaindiv">
                <section className="set-secBs">
                    <div className='banner-container'>
                        <div className='isInline'>
                            <h1 style={{ textAlign: "center" }}>Valuation History</h1>
                        </div>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <label>Date</label>
                                <DatePicker
                                    required={true}
                                    label={"Effective Date"}
                                    defaultValue={effectiveDate}
                                    onChange={(e) => {
                                        setEffectiveDate(e);
                                        setIsShown(false);
                                    }}
                                    errorMessage={"Effective Date is Required"}
                                />
                            </Grid>

                            {roleId !== 3 &&
                                <Grid item xs={12} md={4}>
                                    <label>Customer</label>
                                    <MySelect
                                        options={customerList}
                                        placeholder="Select Customer"
                                        value={selectedCustomer}
                                        onChange={(value) => {
                                            setSelectedCustomer(value);
                                            setIsShown(false);
                                        }}
                                    />
                                </Grid>
                            }
                            {roleId !== 3 &&
                                <Grid item xs={12} md={4}>
                                    <label>Valuer</label>
                                    <MySelect
                                        options={valuerList}
                                        placeholder="Select Valuer"
                                        value={selectedValuer}
                                        onChange={(value) => {
                                            setSelectedValuer(value);
                                            setIsShown(false);
                                        }}
                                    />
                                </Grid>
                            }

                            <Grid item xs={1} md={1}>
                                <button
                                    style={{ marginTop: "45%" }}
                                    onClick={() => {
                                        if (selectedCustomer === "" && effectiveDate === "" && selectedValuer === "") {
                                            toast.error("Please select at least one field");
                                        } else {
                                            getValuationdetails();
                                        }
                                    }}
                                >Go</button>
                            </Grid>
                        </Grid><br />
                        <Divider />
                        {isShown && (
                            <section className="tablecontainer">
                                <MaterialTable
                                    icons={GridIcons}
                                    title={`Valuation History: ${effectiveDate ? getDisplayDate(effectiveDate) : ""}`}
                                    columns={columns}
                                    data={valuationList}
                                    options={options}
                                    style={style}
                                />
                            </section>
                        )}
                    </div>
                </section>
            </div>
            {isLoader && <Loader />}
        </>
    );
};

export default ValuationHistory;
