import axios from "axios";
import { Navigate } from 'react-router-dom';
import toast from "react-hot-toast";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiRequest = async (
  method,
  url,
  data = null,
  isPrivate = false,
  isAdmin = false
) => {
  let headers = {};
  if (isPrivate) {
    const token = localStorage.getItem("userToken");
    if (!token) {
      return <Navigate to="/login" />;
    }
    headers = {
      Authorization: token,
    };
  }

  try {
    const response = await axios({
      method: method,
      url: `${apiUrl}${url}`,
      data: {
        ...data,
      },
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error(
      `Error in ${method} request to ${url}:`,
      error.response || error
    );
    if (error.response.status === 401) {
      localStorage.clear();
      toast.error( "Session Expired. Please Login !!!" );
      window.location.reload();
    }
    if (error.response.status === 500) {
      toast.error(
         error?.response?.data
          ? error?.response?.data
          : "Somthing Went Wrong!!!",
      );
    }
    if (error.response.status === 400) {
      toast.error(
         error?.response?.data?.message
          ? error?.response?.data?.message
          : "Somthing Went Wrong!!!",
      );
    }
    // throw error;
  }
};

export default apiRequest;
