import { useFormik } from 'formik';
import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { BiTrash, BiEditAlt } from "react-icons/bi";
import "../CSS/valuationDetails.css";
import apiRequest from "../APICall";
import toast from "react-hot-toast";
import { getDBFormateDate } from '../DateTimeUtils';
import Loader from '../Components/Loader';

const Valuationdetails = ({ handleValDetails, valDetails }) => {

  // const currentdate = new Date().toISOString().slice(0, 10);
  const dataToMap = JSON.parse(localStorage.getItem("tableData"));
  const [ornamentList, setOrnamentList] = useState([]);
  const temp = JSON.parse(localStorage.getItem("tableData")) || [];
  const [valuationDetails, setValuationsDetails] = useState(temp);
  const [ornamentIdToNameMap, setOrnamentIdToNameMap] = useState({});
  const [isShown, setIsShown] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [dailyprizelist, setDailyprizelist] = useState([]);
  const [todayPrize, setTodayPrize] = useState(0);
  const [rate, setRate] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoader, setIsLoader] = useState(false);


  const userData = JSON.parse(localStorage.getItem("userData"));
  const loggedinId = userData ? userData.ValuerMasterId : "";
  const roleId = userData ? userData.ValuerRoleId : "";
  const jewellerId = userData ? userData.JewellerId : "";
  const CustomerDetails = JSON.parse(localStorage.getItem("CustDetails"));
  const currentdate = CustomerDetails ? CustomerDetails.selectedDate : "";

  useEffect(() => {
    setIsLoader(true);
    apiRequest("GET", `ornamentsgetdata`, null, true, false)
      .then((res) => {
        setOrnamentList(res);
        const idToNameMap = {};
        res.forEach((ornament) => {
          idToNameMap[ornament.OrnamentsMasterId] = ornament.OrnamentName;
        });
        setOrnamentIdToNameMap(idToNameMap);
        setIsLoader(false);
      })
      .catch(function (error) {
        setIsLoader(false);
        toast.error("Try after sometime");
      });
  }, []);

  const tableRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    if (!validateField()) {
      // If form is not valid, return early
      return;
    }

    // Your existing handleClick logic goes here
    const newDetail = {
      Ornament: values.Ornament,
      Qty: values.Qty,
      Purity: values.Purity,
      Gross: values.Gross,
      Net: values.Net,
      CTST: values.CTST,
      Rate: rate,
      Jewellarytype: values.Jewellarytype,
    };

    if (editIndex !== null) {
      // Update existing item
      const updatedValuations = [...valuationDetails];
      updatedValuations[editIndex] = newDetail;
      setValuationsDetails(updatedValuations);
      setEditIndex(null); // Reset editIndex after editing
    } else {
      // Add new item
      setValuationsDetails((prevValuations) => [
        ...prevValuations,
        newDetail,
      ]);
    }

    resetForm();
    setRate(0);
  };


  const updateTableData = () => {
    if (valuationDetails.length >= 0) {
      localStorage.setItem("tableData", JSON.stringify(valuationDetails));
    }
  };

  const handleDelete = () => {
    if (recordToDelete !== null) {
      setValuationsDetails((prevValuations) =>
        prevValuations.filter((valuation, index) => index !== recordToDelete)
      );
      setIsShown(false);
      setRecordToDelete(null);
      updateTableData();
    }
  };

  const openDeleteConfirmation = (index) => {
    setIsShown(true);
    setRecordToDelete(index);
  };

  useEffect(() => {
    getdailyprizedetails();
  }, []);

  useEffect(() => {
    updateTableData();
  }, [valuationDetails]);

  useEffect(() => {
    const beforeUnloadHandler = () => {
      localStorage.removeItem('tableData');
      localStorage.removeItem('CustDetails');
      localStorage.removeItem("CertificateNumber");
      localStorage.removeItem("Updated");
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  const getdailyprizedetails = async () => {
    try {
      const res = await apiRequest("post", `getdailyprizedetailsById`, {
        effectiveDate: currentdate,
        loggedinId,
        roleId,
        jewellerId
      }, true, false);
      const modifiedRes = res.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setDailyprizelist(modifiedRes);
      setTodayPrize(modifiedRes[0]?.Prize)
    } catch (error) {
      setDailyprizelist([]);
      setTodayPrize(0)
      toast.error("Try after sometime");
    }
  };

  const initialObj = {
    Ornament: "",
    Qty: "",
    Purity: "",
    Gross: "",
    Net: "",
    CTST: "",
    Jewellarytype: "",
  };

  const initialValues = initialObj;

  const validationSchema = yup.object({
    Ornament: yup.string().required("Please select ornament"),
    Qty: yup.string().required("Please enter Qty."),
    Purity: yup.string().required("Please enter Purity."),
    Gross: yup.string().required("Please enter Gross."),
    Net: yup.string().required("Please enter Net."),
    CTST: yup.string().required("Please enter CTST."),
    Jewellarytype: yup.string().required("Please enter Jewellarytype."),
  });

  const { values, handleSubmit, handleChange, errors, resetForm, setFieldValue, validateField } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const newDetail = {
        Ornament: values.Ornament,
        Qty: values.Qty,
        Purity: values.Purity,
        Gross: values.Gross,
        Net: values.Net,
        CTST: values.CTST,
        Rate: rate,
        Jewellarytype: values.Jewellarytype,
      };
      if (editIndex !== null) {
        const updatedValuations = [...valuationDetails];
        updatedValuations[editIndex] = newDetail;
        setValuationsDetails(updatedValuations);
        setEditIndex(null);
      } else {
        setValuationsDetails((prevValuations) => [...prevValuations, newDetail]);
      }
      resetForm();
      setRate(0);
    },
  });

  const handlePurityChange = (e) => {
    const { value } = e.target;
    const purity = parseFloat(value);
    if (todayPrize) {
      if (!isNaN(purity) && purity !== 0) {
        const rate = (todayPrize / 24) * purity;
        setRate(rate.toFixed(0));
      } else {
        setRate(0);
      }
    } else {
      toast.error("Please add the value for today.")
      setRate(0);
    }
    handleChange(e);
  };

  const handleEdit = (index) => {

    setEditIndex(index);
    const itemToEdit = valuationDetails[index];
    setIsEditMode(true);
    setRate(itemToEdit.Rate);
    const editedValues = {
      Ornament: itemToEdit.Ornament,
      Qty: itemToEdit.Qty,
      Purity: itemToEdit.Purity,
      Gross: itemToEdit.Gross,
      Net: itemToEdit.Net,
      CTST: itemToEdit.CTST,
      Jewellarytype: itemToEdit.Jewellarytype,
      Rate: itemToEdit.Rate,
    };

    Object.keys(editedValues).forEach((key) => {
      setFieldValue(key, editedValues[key]);
    });

    setRate(itemToEdit.Rate);
  };

  return (
    <>
      <div>
        <section className="section-ornaments">
          <div className="ornamentpop">
            <h2 className="form-header">
              {" "}
              Enter Valuation Details :
            </h2>

            <form onSubmit={handleSubmit}>
              <div className="ornamentpopmain">
                <div className="val-container">

                  <div className="input-cont">
                    <label>Jwellery Type</label>

                    <select
                      style={{ width: "11.5rem", height: "1.9rem" }}
                      className="val-det-input"
                      id="Jewellarytype"
                      name="Jewellarytype"
                      value={values.Jewellarytype}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        select
                      </option>
                      <option>Real Jewellary</option>
                      <option>Imitation</option>
                    </select>
                    {errors.Jewellarytype && (
                      <div className="errorcss">{errors.Jewellarytype}</div>
                    )}
                  </div>

                  <div>
                    <label>Ornaments Name </label>
                    <br />
                    <select
                      style={{ width: "11.4rem", height: "1.9rem" }}
                      className="val-det-input"
                      id="Ornament"
                      name="Ornament"
                      value={values.Ornament}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select a Ornament
                      </option>
                      {ornamentList && ornamentList.length > 0 ? (
                        ornamentList.map((ornament) => (
                          <option
                            key={ornament.OrnamentsMasterId}
                            value={ornament.OrnamentsMasterId}
                          >
                            {ornament.OrnamentName}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No Ornaments Found
                        </option>
                      )}
                    </select>
                    {errors.Ornament && (
                      <div className="errorcss">{errors.Ornament}</div>
                    )}
                  </div>
                </div>
                <div className="val-container">
                  <div className="input-cont" style={{paddingLeft:"0px"}}>
                    <label>Purity </label>
                    <input
                      className="val-det-input"
                      id="Purity"
                      type="number"
                      name="Purity"
                      value={values.Purity}
                      onChange={handlePurityChange} // Using handlePurityChange to calculate Rate
                    />
                    {errors.Purity && (
                      <div className="errorcss">{errors.Purity}</div>
                    )}
                  </div>
                  <div className="input-cont">
                    <label>Qty. </label>
                    <input
                      className="val-det-input"
                      id="Qty"
                      type="text"
                      name="Qty"
                      value={values.Qty}
                      onChange={handleChange}
                    />
                    {errors.Qty && <div className="errorcss">{errors.Qty}</div>}
                  </div>
                </div>

                <div className="val-container">
                  <div className="input-cont">
                    <label>Gross </label>

                    <input
                      className="val-det-input"
                      id="Gross"
                      type="text"
                      name="Gross"
                      value={values.Gross}
                      onChange={handleChange}
                    />
                    {errors.Gross && (
                      <div className="errorcss">{errors.Gross}</div>
                    )}
                  </div>
                  <div className="input-cont">
                    <label>Net </label>

                    <input
                      className="val-det-input"
                      id="Net"
                      type="text"
                      name="Net"
                      value={values.Net}
                      onChange={handleChange}
                      disabled={!todayPrize}
                    />
                    {errors.Net && <div className="errorcss">{errors.Net}</div>}
                  </div>
                </div>

                <div className="val-container">
                  <div className="input-cont">
                    <label>CT. ST </label>
                    <input
                      className="val-det-input"
                      id="CTST"
                      type="text"
                      name="CTST"
                      value={values.CTST}
                      onChange={handleChange}
                    />
                    {errors.CTST && (
                      <div className="errorcss">{errors.CTST}</div>
                    )}
                  </div>
                  <div className="input-cont">
                    <label>Rate </label>

                    <input
                      className="val-det-input"
                      id="Rate"
                      type="text"
                      name="Rate"
                      value={rate} // Displaying calculated Rate value
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <center>
                <button
                  className="val-det-btn"
                  type="submit"
                >
                  Add Item
                </button>
              </center>
            </form>
          </div>

          <div className="ornamentsdisplay">
            <section className="tablecontainer2">
              <center>
                <table className="valuation-table" ref={tableRef} id="myTable">
                  <thead>
                    <th>JEWELLERY TYPE</th>
                    <th>Ornaments</th>
                    <th>QTY.</th>
                    <th>PURITY</th>
                    <th>GROSS</th>
                    <th>NET</th>
                    <th>CT. ST</th>
                    <th>RATE</th>
                    <th>Action</th>
                  </thead>

                  {valuationDetails && valuationDetails.length !== 0
                    ? valuationDetails.map((ornamentdata, index) => (
                      <tr key={index}>
                        {" "}
                        <td>{ornamentdata.Jewellarytype}</td>
                        <td>{ornamentIdToNameMap[ornamentdata.Ornament]}</td>
                        <td>{ornamentdata.Qty}</td>
                        <td>{ornamentdata.Purity}</td>
                        <td>{ornamentdata.Gross}</td>
                        <td>{ornamentdata.Net}</td>
                        <td>{ornamentdata.CTST}</td>
                        <td>{ornamentdata.Rate}</td>
                        <td>
                          <BiTrash
                            className="delete-icon"
                            onClick={() => openDeleteConfirmation(index)}
                            style={{ cursor: "pointer" }}
                          />
                          <BiEditAlt
                            className="delete-icon"
                            onClick={() => handleEdit(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    ))
                    : ""}
                </table>
              </center>
            </section>
          </div>
        </section >
      </div >

      <div div className={isShown === true ? "overlay-main" : ""}>
        {isShown && <div className="container">
          <i class="fa-solid fa-xmark close-button" onClick={() => setIsShown(false)}></i>
          <h1 className="popup-header">Are you sure?</h1>
          <h3 className="popup-text">Are you sure you want to delete this record?</h3>
          <div className="button-container">
            <button className="popup-button" onClick={() => setIsShown(false)}>Cancel</button>
            <button className="popup-button" onClick={handleDelete}>Delete</button>
          </div>
        </div>}
      </div >
      {isLoader && <Loader />}
    </>
  );
};

export default Valuationdetails;
