import React from "react";
import Select from "react-select";

const MySelect = ({
    options,
    value,
    onChange,
    onBlur,
    placeholder,
    width,
    padding,
    className
}) => {
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: width ? width : "100%"
        }),
        option: (provided, state) => ({
            ...provided,
            color: "black",
            backgroundColor: state.isSelected ? "#f2e9e9" : "white",
            "&:hover": {backgroundColor: state.isSelected ? "#f2e9e9" : "#f2e9e9",
            }
        }),
       
        valueContainer: (provided, state) => ({
            ...provided,
            padding: padding ? padding : "2px"
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "gray",
            padding: "0px 2px"
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isActive ? 'var(--font)' : 'var(--font)',
            border: state.isFocused ? '1px solid var(--font)' : '1px solid var(--font)',
            boxShadow: "none",
            ':hover': {
                borderColor: "var(--font)"
            }
        }),
    };

    return (
        <Select
            value={options.find(option => option.value === value) || null}
            onChange={(option) => {
                onChange(option.value);
            }}
            onBlur={onBlur}
            isSearchable
            options={options}
            placeholder={placeholder}
            styles={customStyles}
            className="selectpurpose select2 select-fields"
        />
    );
};

export default MySelect;
