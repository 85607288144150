import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "../Common/Header Footer/header";
import Footer from "../Common/Header Footer/footer";
import { Toaster } from "react-hot-toast";
import Loginpage from "../Auth Pages/Login";
import SignUp from "../Auth Pages/Signup";
import Firstpage, { HomeBlog } from "../Pages/Home";
import ValuationSteps from "../Pages/Stepper";
import ValuationProfilePage from "../Pages/Profile";
import ValuerAdminPage from "../Pages/SettingsPage/Valuer";
import CarateRate from "../Pages/SettingsPage/CarateRate";
import ManageJewellerPage from "../Pages/SettingsPage/Jeweller";
import ManageAdminPage from "../Pages/SettingsPage/Admin";
import Settings from "../Pages/SettingsPage/SettingsPage";
import BannerAdminPage from "../Pages/SettingsPage/Banner";
import ValuationHistory from "../Pages/SettingsPage/ValuationHistory";
import GetCertificate from "../Pages/GetCertificate";
import PaymentComponent from "../Pages/Payment";
import PaymentSuccess from "../Pages/PaymentSuccess";
import PaymentCancel from "../Pages/PaymentCancel";
import CheckValuer from "../Pages/CheckValuer";
import Blogs, { AddBlogPost } from "../Pages/Blogs";
import Privacy from "../Pages/PrivacyPolicy";

const Index = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const loggedinId = userData ? userData.ValuerMasterId : "";
  const roleId = userData ? userData.ValuerRoleId : "";
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      let userData = localStorage.getItem("userData");
      if (
        !location.pathname.includes("payment") &&
        userData &&
        JSON.parse(userData).isSubscribed == 0
      ) {
        navigate("/payment");
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [location]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <div className="main">
      <div className="mainHead">
        <Header isLoggedIn={isLoggedIn} />
      </div>
      <div className="mainpage" >
        <Toaster position="top-right" />
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Loginpage setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/" element={<Firstpage />} />
          <Route path="/checkvaluer" element={<CheckValuer />} />
          <Route
            path="/ValuerProfile"
            element={
              <PrivateRoute
                element={ValuationProfilePage}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/valuationsteps"
            element={
              <PrivateRoute
                element={ValuationSteps}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          {/* Admin Pages */}
          <Route
            path="/valuer"
            element={
              <PrivateRoute
                element={ValuerAdminPage}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/dailycaratrate"
            element={
              <PrivateRoute
                element={CarateRate}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/jeweller"
            element={
              <PrivateRoute
                element={ManageJewellerPage}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/manageadmin"
            element={
              <PrivateRoute
                element={ManageAdminPage}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/ornaments"
            element={
              <PrivateRoute
                element={Settings}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/banner"
            element={
              <PrivateRoute
                element={BannerAdminPage}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/valuationhistory"
            element={
              <PrivateRoute
                element={ValuationHistory}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/generateCertificate"
            element={
              <PrivateRoute
                element={GetCertificate}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          {/* Admin Pages */}
          <Route
            path="/blogs"
            element={
              <PrivateRoute
                element={Blogs}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route
            path="/blogs/addBlog"
            element={
              <PrivateRoute
                element={AddBlogPost}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
           <Route
            path="/blogs/editBlog/:postId"
            element={
              <PrivateRoute
                element={AddBlogPost}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
           <Route
            path="/blog/:permalink"
            element={
              <HomeBlog />
            }
          />
           <Route
            path="/privacypolicy"
            element={
              <Privacy/>
            }
          />
        </Routes>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

const PrivateRoute = ({ isLoggedIn, setIsLoggedIn, element, ...rest }) => {
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setAuthChecked(true);
  }, [setIsLoggedIn]);

  if (!authChecked) {
    return <div>Loading...</div>;
  }

  return isLoggedIn ? (
    React.createElement(element, rest)
  ) : (
    <Navigate to="/login" />
  );
};

const NotFound = () => {
  return (
    <div>
      <h1>404 - Not Found</h1>
    </div>
  );
};

export default Index;
