import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import "../CSS/loginpage.css";
import toast from "react-hot-toast";
import apiRequest from "../APICall";
import { Link, useNavigate } from "react-router-dom";
import floral from "../Assets/images/floral.svg";
import floral2 from "../Assets/images/floral-2.svg";
import ellipse from "../Assets/images/Ellipse 16.svg";
import { Grid } from "@material-ui/core";
import MySelect from "../Components/AutoComplete";
import SingleCheckbox from "../Components/SingleCheckBox";

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

function Signuppage() {
    const navigate = useNavigate();
    const [isShown, setIsShown] = useState(false)
    const initialValues = {
        jewellerName: "",
        mobileNumber: "",
        email: "",
        address: "",
        registrationNumber: "",
        admin: "",
        isActive: false
    };

    const validationSchema = yup.object().shape({
        jewellerName: yup.string().required("Jeweller Name is required"),
        admin: yup.string().required("Admin Name is required"),
        plan: yup.string().required("Please select a plan"),
        password: yup
            .string()
            .required("Please enter password")
            .min(8, "Password should be greater than 8 characters.")
            .max(15, "Password should be less than 15 characters"),
        mobileNumber: yup
            .string()
            .required("Please enter Mobile Number")
            .min(10, "Mobile Number should be minimum 10 digit.")
            .matches(phoneRegExp, "Phone number is not valid"),
        email: yup
            .string()
            .email("Invalid email address")
            .required("Email is required"),
        address: yup.string().required("Address is required"),
        registrationNumber: yup
            .string()
            .required("Please enter registration number")
            .min(4, "Registration Number should be 4-12 characters")
            .max(12, "Registration Number should be 4-12 characters"),
        isActive: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    });

    const { values, handleSubmit, handleChange, setFieldValue, errors } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                let selectedPlan = plansList.find((x) => x.value == values.plan);
                const res = await apiRequest(
                    "POST",
                    "signup",
                    {
                        jewellerName: values.jewellerName,
                        mobile: values.mobileNumber,
                        email: values.email,
                        address: values.address,
                        registrationNumber: values.registrationNumber,
                        adminName: values.admin,
                        password: values.password,
                        planId: selectedPlan?.PlanMasterId,
                        noOfDays: selectedPlan?.FreeTrialDays,
                        maxUsers: selectedPlan?.MaxUsers,
                        productMasterId: selectedPlan?.ProductMasterId,
                    },
                    false,
                    false
                );
                if (res.status === 200) {
                    toast.success("Sign up successfully, Please Login");
                    navigate("/login");
                } else if (res.status === 501) {
                    toast.error("Mobile number is already present, Please Login");
                }
            } catch (error) {
                console.error(error);
                toast.error("Something Went Wrong");
            } finally {
                setSubmitting(false);
            }
        },
    });

    const [plansList, setPlansList] = useState([]);
    //   const navigate = useNavigate();
    const getPlans = async () => {
        try {
            const res = await apiRequest(
                "POST",
                "subscription/getPlans",
                {},
                false,
                false
            );

            if (res.status === 200) {
                // toast.success(res.message);
                setPlansList(
                    res.planList
                        .filter((y) => y.FreeTrialDays > 0)
                        .map((x) => {
                            return { ...x, label: x.PlanName, value: x.PlanMasterId };
                        })
                );
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Something Went Wrong");
        }
    };
    useEffect(() => {
        getPlans();
    }, []);

    return (
        <>
            <div className="signupmain">
                <img className="floral-img-3" src={floral} />
                <img className="floral-img-4" src={floral2} />

                {/* <img className="ellipse-img-4" src={ellipse}/> */}
                {!isShown &&
                
                    <><label className="question">Are You Registered Government Jewellery Valuer?</label>
                    <img className="ellipse-img-3" src={ellipse} />
                        <h1>Sign up</h1>
                        <form onSubmit={handleSubmit} className="signup-form">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Jeweller Name: *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Jeweller Name"
                                            name="jewellerName"
                                            value={values.jewellerName}
                                            onChange={handleChange("jewellerName")}
                                            maxLength={30}
                                        />
                                        {errors.jewellerName && (
                                            <div className="errorcss">{errors.jewellerName}</div>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Admin Name: *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Admin Name"
                                            name="admin"
                                            value={values.admin}
                                            onChange={handleChange("admin")} 
                                            maxLength={30}
                                        />
                                        {errors.admin && <div className="errorcss">{errors.admin}</div>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Mobile Number: *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Mobile Number"
                                            maxLength={10}
                                            name="mobileNumber"
                                            value={values.mobileNumber}
                                            onChange={handleChange("mobileNumber")}
                                        />
                                        {errors.mobileNumber && (
                                            <div className="errorcss">{errors.mobileNumber}</div>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Email: *</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange("email")}
                                            maxLength={30}
                                        />
                                        {errors.email && <div className="errorcss">{errors.email}</div>}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Registration Number: *</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Registration Number"
                                            name="registrationNumber"
                                            value={values.registrationNumber}
                                            onChange={handleChange("registrationNumber")}
                                            maxLength={12}
                                        />
                                        {errors.registrationNumber && (
                                            <div className="errorcss">{errors.registrationNumber}</div>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Password: *</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange("password")}
                                            maxLength={30}
                                        />
                                        {errors.password && (
                                            <div className="errorcss">{errors.password}</div>
                                        )}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <label>Plan: *</label>
                                    <MySelect
                                        options={plansList}
                                        placeholder="Select Plan"
                                        value={values.plan}
                                        onChange={(value) => {
                                            handleChange({ target: { name: "plan", value } });
                                        }}
                                    />
                                    {errors.plan && <div className="errorcss" style={{paddingTop:"2%"}}>{errors.plan}</div>}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="form-group">
                                        <label htmlFor="mobileNumber">Address: *</label>
                                        <textarea
                                            style={{ resize: "none",  }}
                                            className="form-control"
                                            type="text"
                                            placeholder="Address"
                                            name="address"
                                            value={values.address}
                                            onChange={handleChange("address")}
                                            maxLength={200}
                                        />
                                        {errors.address && (
                                            <div className="errorcss">{errors.address}</div>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} style={{ display: "flex", paddingTop: "2%", justifyContent: "center", alignItems: "center" }}>
                                <Grid item xs={12} md={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "3%" }}>
                                    <SingleCheckbox
                                        label={false}
                                        checked={values.isActive}
                                        onChange={(e) => {
                                            setFieldValue("isActive", e.target.checked);
                                            if (values.isActive === false) {
                                                setIsShown(true);
                                            }
                                        }}
                                    />
                                    <label><p>Consent and Terms and Conditions *</p></label>
                                </Grid>
                                {errors.isActive && <div className="errorcss" style={{ display: "flex", justifyContent: "center" }}>{errors.isActive}</div>}
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <button className="btn btn-primary" type="submit">
                                    Signup
                                </button>
                                <p style={{ zIndex: "1" }}>
                                    Already have an account ?{" "}
                                    <Link
                                        to="/login"
                                        style={{ color: "var(--font)", textDecoration: "none" }}
                                    >
                                        <b style={{ color: "var(--font)" }}>Login here</b>
                                    </Link>{" "}
                                </p>
                            </Grid>

                        </form></>
                }
                {isShown &&
                    <div className="agreement">
                        <div>
                            <h1 >Consent and Agreement</h1>
                            <h4 style={{ padding: "0px" }}>By signing up for our Jewellery valuation software, you acknowledge and agree to the following terms and conditions:</h4>


                            <h3>Data Entry and Accuracy:</h3>
                            <div>
                                <h5>You are solely responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of the data you enter into our system.
                                    We do not validate, verify, or endorse the data you input, and we are not responsible for any errors or omissions in the information you provide.</h5>
                            </div>
                            <div>
                                <h3>Data Storage and Security:</h3>
                                <h5>We will store the data you input securely and take reasonable measures to protect it from unauthorized access, alteration, or destruction.
                                    Despite our efforts, we cannot guarantee absolute security, and you agree that we are not liable for any unauthorized access to or loss of your data.</h5>
                            </div>
                            <h4>Use of Data</h4>
                            <h5>The data you enter will be used solely for the purpose of storing your jewellery valuation details.
                                We will not share, sell, or distribute your data to third parties without your explicit consent, except as required by law.
                            </h5>
                            <h4>User Responsibilities</h4>
                            <h5>You must maintain the confidentiality of your account credentials and notify us immediately of any unauthorized use of your account.
                                You are responsible for all activities that occur under your account, including any data entered by you or any third party using your credentials.</h5>
                            <h4>Limitation of Liability</h4>
                            <h5>Our software is provided "as is" without any warranties, express or implied, including but not limited to, the implied warranties of merchantability and fitness for a particular purpose.
                                We shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of your use of the software or the data stored within it.</h5>
                            <h4>Termination</h4>
                            <h5>We reserve the right to terminate or suspend your account at any time, with or without notice, for any reason, including but not limited to, a breach of these terms and conditions.</h5>
                            <h3>Modifications to Terms:</h3>
                            <h5>We may update these terms and conditions from time to time. We will notify you of any changes by posting the new terms on our website or through the software interface.
                                Your continued use of the software after any such changes constitutes your acceptance of the new terms.
                                By creating an account, you confirm that you have read, understood, and agree to be bound by these terms and conditions.</h5> </div>
                        <div style={{gap:"10%", display:"flex", justifyContent:"center"}}>
                            <button style={{ width: "75px", border: "none", height: "2rem", borderRadius: "5%", color: "white", cursor: "pointer", backgroundColor: "var(--font)" }} onClick={() => {
                                setIsShown(!isShown);
                                setFieldValue("isActive", false);
                            }}>Close</button><button style={{ width: "75px", border: "none", height: "2rem", borderRadius: "5%", color: "white", cursor: "pointer", backgroundColor: "var(--font)" }} onClick={() => {
                                setIsShown(!isShown);
                                setFieldValue("isActive", true);
                            }}>Accept</button>
                        </div>


                    </div>
                }
            </div>

        </>
    );
}

export default Signuppage;
