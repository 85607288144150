import React, { useEffect, useState } from "react";
import "../CSS/customerDetails.css";
import apiRequest from "../APICall";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { SideSheet } from "evergreen-ui";
import * as yup from "yup";
import AddCustomer from "./AddCustomer";

//to set
import { getDBFormateDateTime } from "../DateTimeUtils";

//to show
import { getDateMonthYear } from "../DateTimeUtils";
import { Grid } from "@material-ui/core";
import MySelect from "../Components/AutoComplete";
import Loader from "../Components/Loader";

const Custodetails = ({ handleNext, handleCustDetails }) => {
  const [customerList, setCustomerList] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [valuationPurposeList, setValuationPurposeList] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().slice(0, 10));
  const [isLoader, setIsLoader] = useState(false);

  const fetchCustomerList = () => {
    setIsLoader(true);
    apiRequest("GET", `customerdetailsget`, null, true, false)
      .then((res) => {
        const transformedData = res.map(item => ({
          value: item.value,
          label: item.name
        }));
        setIsLoader(false);
        setCustomerList(transformedData);
      })
      .catch(function (error) {
        setIsLoader(false);
        toast.error("Try after sometime");
      });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  useEffect(() => {
    setIsLoader(true);
    apiRequest("GET", `valuationpurposeget`, null, true, false)
      .then((res) => {
        const transformedData = res.map(item => ({
          value: item.PurposeOfValuationId,
          label: item.PurposeOfValuationName
        }));
        setIsLoader(false);
        setValuationPurposeList(transformedData);
      })
      .catch(function (error) {
        setIsLoader(false);
        toast.error("Try after sometime");
      });
  }, []);

  const handleEvent = (event) => {
    setCurrentDate(new Date(event.target.value));
    localStorage.setItem("date", getDBFormateDateTime(currentDate));
  };

  const tempDate = (localStorage.getItem("date"));

  const initialObj = {
    selectedCustomer: "",
    selectedPurpose: "",
    selectedawbNumber: "",
    selectedInvoiceNumber: "",
    selectedDate: currentDate,
  };


  const temp = JSON.parse(localStorage.getItem("CustDetails"));
  const initialValues = localStorage.getItem("CustDetails") ? temp : initialObj;

  const validationSchema = yup.object({
    selectedCustomer: yup.string().required("Please select customer"),
    selectedPurpose: yup.string().required("Please select a purpose"),
  });

  const handleClick = (info, date) => {
    handleCustDetails(info);
  };

  useEffect(() => {
    const beforeUnloadHandler = () => {
      localStorage.removeItem('CustDetails');
      localStorage.removeItem('tableData');
      localStorage.removeItem("CertificateNumber");
      localStorage.removeItem("Updated");
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleNext();
      handleClick(values);
      localStorage.setItem("CustDetails", JSON.stringify(values));
    },
  });

  return (
    <div className="step1form">
      <form className="formsite" onSubmit={handleSubmit}>
        <div style={{display:"flex", justifyContent:"end", width:"95%"}}>
          <button className="addcustomer" onClick={(e) => { e.preventDefault(); setIsShown(true); }}>
            {" "}
            +{" "}
          </button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <label>Search Customer: *</label>
            <MySelect
              options={customerList}
              placeholder="Select Customer"
              value={values.selectedCustomer}
              onChange={(value) => {
                handleChange({ target: { name: "selectedCustomer", value } });
              }}
            />
            {errors.selectedCustomer && (
              <div className="errorcss">{errors.selectedCustomer}</div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <label>Purpose of valuation *</label>
            <MySelect
              options={valuationPurposeList}
              placeholder="Select Purpose"
              value={values.selectedPurpose}
              onChange={(value) => {
                handleChange({ target: { name: "selectedPurpose", value } });
              }}
            />
            {errors.selectedPurpose && (
              <div className="errorcss">{errors.selectedPurpose}</div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <label>A.W.B. number</label>
            <input
              className='inputFieldGrid'
              id="selectedawbNumber"
              type="number"
              name="selectedawbNumber"
              value={values.selectedawbNumber}
              onChange={handleChange}
            />
            {errors.selectedawbNumber && (
              <div className="errorcss">{errors.selectedawbNumber}</div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <label>Invoice number</label>
            <input
              className='inputFieldGrid'
              id="selectedInvoiceNumber"
              type="number"
              name="selectedInvoiceNumber"
              value={values.selectedInvoiceNumber}
              onChange={handleChange}
            />
            {errors.selectedInvoiceNumber && (
              <div className="errorcss">{errors.selectedInvoiceNumber}</div>
            )}
          </Grid>
        </Grid>
        <div className="CustoButton">
          <button className="save-button" type="submit" onClick={handleClick}>
            Save
          </button>
        </div>
      </form>

      {isShown && (
        <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)}>
          <AddCustomer setIsShown={setIsShown} fetchCustomerList={fetchCustomerList} />
        </SideSheet>
      )}
      {isLoader && <Loader />}
    </div>
  );
};

export default Custodetails;
