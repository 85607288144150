import { React, useState } from "react";
import { useFormik } from 'formik';
import * as yup from "yup";
import apiRequest from "../APICall";
import toast from "react-hot-toast";
import '../CSS/loginpage.css';
import { Link, useNavigate } from "react-router-dom";
import floral from "../Assets/images/floral.svg"
import floral2 from "../Assets/images/floral-2.svg"
import ellipse from "../Assets/images/Ellipse 16.svg"
import { Grid } from "@material-ui/core";

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Loginpage = ({ setIsLoggedIn }) => {
    

    const navigate = useNavigate();

    const initialValues = {
        mobileNumber: "",
        password: "",
    };
    const validationSchema = yup.object({
        mobileNumber: yup
            .string()
            .required("Please enter Mobile Number")
            .min(10, "Mobile Number should be minimum 10 digit.")
            .max(10, "Mobile Number should be maximum 10 digit.")
            .matches(phoneRegExp, "Phone number is not valid"),

        password: yup
            .string()
            .required("Please enter Password")
            .min(8, "Password is too short - should be 8 chars minimum."),
    });

    const { values, handleSubmit, handleChange, errors } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const res = await apiRequest(
                    "POST",
                    "login",
                    { mobile: values.mobileNumber, password: (values.password) },
                    false,
                    false
                );

                if (res.status === 200) {
                    localStorage.setItem("userData", JSON.stringify(res.data));
                    localStorage.setItem("userToken", res.data.JWTToken);
                 
                    setIsLoggedIn(true);
                    console.log(res.data)
                  
                    if (res.data.ValuerRoleId == 5) {
                        navigate("/")
                    }
                    else{
                        navigate("/valuationsteps");
                    }
                    toast.success("Logged In Succesfully!");
                    
                } else if (res.status === 500) {
                    toast.error("Mobile number is not found. Please sign up!");
                } else if (res.status === 501) {
                    toast.error("Password not match");
                }
            } catch (error) {
                console.error(error);
                toast.error("Something Went Wrong");
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <div className="loginmain">
            <img className="floral-img-1" src={floral} />
            <img className="floral-img-2" src={floral2} />
            <img className="ellipse-img" src={ellipse} />
            {/* <img className="ellipse-img-2" src={ellipse}/> */}
            <h1>Login</h1>
            <form onSubmit={handleSubmit} className="login-form">
                <Grid container spacing={2} style={{display:"flex", justifyContent:"center"}}>
                    <Grid item xs={12} md={6}>
                        <div className="form-group">
                            <label htmlFor='mobileNumber'>Mobile Number*</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Mobile Number"
                                maxLength={10}
                                name="mobileNumber"
                                value={values.mobileNumber}
                                onChange={handleChange}
                            />
                            {errors.mobileNumber && <div className="errorcss">{errors.mobileNumber}</div>}
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ display: "flex", justifyContent:"center"}}>
                    <Grid item xs={12} md={6}>
                        <div className="form-group">
                            <label htmlFor='password'>Password*</label>
                            <input
                                className="form-control"
                                type="password"
                                placeholder="Enter Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange("password")}
                                maxLength={30}
                            />
                            {errors.password && <div className="errorcss">{errors.password}</div>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <button className="btn btn-primary" type="submit">
                        Login
                    </button>
                    <p style={{ zIndex: '1' }}>Don’t have an account? <Link to="/Signup" style={{ color: 'var(--font)', textDecoration: 'none', position: "relative" }}><b>Create new</b></Link></p>
                </Grid>


            </form>
        </div >
    )
}
export default Loginpage;
