import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from 'formik';
import "../../CSS/settingspage.css";
import "../../CSS/settingspage.css";
// import { BiRevision } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";
import { BiCheck } from "react-icons/bi";
import { BiX } from "react-icons/bi";
import { Dialog, Pane } from "evergreen-ui";
import apiRequest from "../../APICall";
import toast from "react-hot-toast";
import Sidebar from "../../Pages/SettingsPage/Sidebar";
import Pagination from "../../Pages/Pagination";
import MaterialTable from "material-table";
import { GridIcons, actionColumnStyle, options, style } from "../../Components/GridConfig";
import { Tooltip } from "@material-ui/core";
import Loader from "../../Components/Loader";

const Settings = () => {
  const [ornamentList, setOrnamentList] = useState([]);
  const [ornamentIdToNameMap, setOrnamentIdToNameMap] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [isShownDelete, setIsShownDelete] = useState(false);
  const [ornamentToDelete, setOrnamentToDelete] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const loggedinId = userData ? userData.ValuerMasterId : "";
  const roleId = userData ? userData.ValuerRoleId : "";

  useEffect(() => {
    ornamentGetData();
  }, []);

  const ornamentGetData = async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest("GET", `ornamentsgetdata`, null, true, false);
      const modifiedRes = res.map((item, index) => ({
        ...item,
        index: index + 1
      }));
      setIsLoader(false);
      setOrnamentList(modifiedRes);
      const idToNameMap = {};
      res.forEach(ornament => {
        idToNameMap[ornament.OrnamentsMasterId] = ornament.OrnamentName;
      });
      setOrnamentIdToNameMap(idToNameMap);
    } catch (error) {
      setIsLoader(false);
      toast.error("Try after sometime");
    }
  };


  const initialValues = {
    Ornament: "",
  };

  const validationSchema = yup.object({
    Ornament: yup
      .string()
      .required("Please enter ornament name"),
  });

  const { values, handleSubmit, handleChange, errors, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoader(true);
      try {
        apiRequest("POST", `updateOrnament`,
          {
            OrnamentName: values.Ornament,
            loggedinId
          },
          true, false)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.message);
              ornamentGetData();
              resetForm();
              setIsShown(false);
              setIsLoader(false);
            } else {
              toast.error(res.message);
            }
          })
          .catch(function (error) {
            setIsLoader(false);
            toast.error("Try after sometime")
          });
      } catch (error) {
        setIsLoader(false);
        toast.error("Try after sometime")
      }
    }
  });

  const handleDelete = async (ornamentId) => {
    setIsLoader(true);
    try {
      const response = await apiRequest("POST", `deleteornament`, { ornamentId, loggedinId }, true, false);
      if (response.status === 200) {
        toast.success(response.message);
        setOrnamentToDelete(null);
        ornamentGetData();
        setIsLoader(false);
      } else {
        setIsLoader(false);
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoader(false);
      toast.error("An error occurred while deleting the ornament.");
    }
  };

  const columns = [
    {
      title: "Sr.No",
      field: "index"
    },
    {
      title: "ORNAMENT NAME",
      field: "OrnamentName",
    },
    {
      title: "ACTION",
      ...actionColumnStyle,
      render: ({ tableData: { id } }) => (
        <div className="table-edit-controls">
          <Tooltip title="Click to delete" style={{ cursor: 'pointer' }}>
            <BiTrash onClick={() => handleDelete(ornamentList[id].OrnamentsMasterId)} />
          </Tooltip>
        </div>
      ),
      printable: false,
    },
  ]

  return (
    <>
      <div className={isShown === true ? "overlay-main" : null}>
        {isShown && (
          <div className="ornamentform">
            <i
              class="fa-solid fa-xmark close-button"
              onClick={() => setIsShown(false)}
            ></i>

            <form onSubmit={handleSubmit}>
              <input
                className="ornamentinput"
                id="Ornament"
                type="text"
                name="Ornament"
                value={values.Ornament}
                onChange={handleChange}
                placeholder="Ornament Name"
              />
              {errors.Ornament && (<div className="errorcss">{errors.Ornament}</div>)}
              <br />
              <button className="addornamentbtn" type="submit">
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
      <div className="settingsmaindiv">
        <section className="set-secB">
          <div className="first">
            <div className='isInline'>
            <h1 style={{ display:"flex" ,textAlign: "center", alignItems:"center", paddingLeft:"1%" }}>  <Sidebar />Manage Ornaments</h1>
              <button className="add-ornament-btn" onClick={() => setIsShown(true)}>
                Add Ornament
              </button>
            </div>
            <section className="tablecontainer" style={{ width: "60%" }}>
              <MaterialTable
                icons={GridIcons}
                title={`Ornaments List`}
                columns={columns}
                data={ornamentList}
                options={options}
                style={style}
              />
            </section>
          </div>
        </section>
      </div>
      {isLoader && <Loader/>}
    </>
  );
};

export default Settings;
