import React, { useState, useEffect, useRef } from "react";
import "../CSS/valuationReport.css";
import apiRequest from "../APICall";
import toast from "react-hot-toast";
import { getDisplayDate, getDateMonthYear } from "../DateTimeUtils";
import { ToWords } from "to-words";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import Loader from "../Components/Loader";

const ReportVJ = () => {
  const toWords = new ToWords();
  const [awbNumber, setAWBNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [purposeOfValuation, setPurposeOfValuation] = useState("");
  const [valuationDate, setValuationDate] = useState("");
  const [valuationReport, setValuationReport] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [customerDataId, setCustomerDataId] = useState([]);
  const [jewellerList, setJewellerList] = useState([]);
  const [ornamentList, setOrnamentList] = useState([]);
  const [realJewellery, setRealJewellery] = useState([]);
  const [imitationJewellery, setImitationJewellery] = useState([]);
  const [savedDraft, setSavedDraft] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [showQR, setShowQR] = useState(false);
  let type = "pdf";

  const [certificateNumber, setCertificateNumber] = useState("");
  const valuationDetails = JSON.parse(localStorage.getItem("tableData"));
  const customerData = JSON.parse(localStorage.getItem("CustDetails"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const updated = JSON.parse(localStorage.getItem("Updated"));
  const certificateNo = localStorage.getItem("CertificateNumber");
  const loggedinId = userData ? userData.ValuerMasterId : "";
  const valuerName = userData ? userData.ValuerName : "";
  const roleId = userData ? userData.ValuerRoleId : "";
  const jewellerId = userData ? userData.JewellerId : "";

  let totalNetReal = 0;
  let totalGrossReal = 0;
  let totalQtyReal = 0;
  let totalAmountReal = 0;
  let totalAmountImitation = 0;
  let totalQtyImit = 0;
  let totalGrossImit = 0;
  let totalNetImit = 0;

  const customerGetDataById = async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest(
        "GET",
        `customerGetDataById?customerId=${customerData?.selectedCustomer}`,
        null,
        true,
        false
      );

      const modifiedRes = res.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setCustomerDataId(modifiedRes);
      const certificateNumber = () => {
        const timestamp = Date.now().toString().slice(0, 8);
        const firstName = modifiedRes[0]?.FirstName;
        const firstFourCapital = firstName
          ? firstName.slice(0, 4).toUpperCase()
          : "";
        return `${firstFourCapital}-${timestamp}`;
      };
      setCertificateNumber(updated ? certificateNo : certificateNumber());
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      toast.error("Try after sometime");
    }
  };

  const ornamentGetData = async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest(
        "GET",
        `ornamentsgetdata`,
        null,
        true,
        false
      );
      const modifiedRes = res.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setOrnamentList(modifiedRes);
      setIsLoader(false);
    } catch (error) {
      toast.error("Try after sometime");
      setIsLoader(false);
    }
  };

  const getJewellerData = async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest(
        "GET",
        `getJewellerDataById?jewellerId=${jewellerId}`,
        null,
        true,
        false
      );

      const modifiedRes = res.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setJewellerList(modifiedRes);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      toast.error("Try after sometime");
    }
  };

  const getPurposeById = async () => {
    setIsLoader(true);
    try {
      const res = await apiRequest(
        "GET",
        `getPurposeById?purposeId=${customerData.selectedPurpose}`,
        null,
        true,
        false
      );

      const modifiedRes = res.map((item, index) => ({
        ...item,
        index: index + 1,
      }));
      setPurposeOfValuation(modifiedRes[0].PurposeOfValuationName);
      setIsLoader(false);
    } catch (error) {
      setIsLoader(false);
      toast.error("Try after sometime");
    }
  };

  useEffect(() => {
    customerGetDataById();
    getPurposeById();
    getJewellerData();
    ornamentGetData();

    const realJewelleryData = valuationDetails?.filter(
      (item) => item.Jewellarytype === "Real Jewellary"
    );
    const imitationJewelleryData = valuationDetails?.filter(
      (item) => item.Jewellarytype === "Imitation"
    );
    setCustomerId(customerData?.selectedCustomer);
    setAWBNumber(
      customerData.selectedawbNumber !== ""
        ? customerData.selectedawbNumber
        : "-"
    );
    setInvoiceNumber(
      customerData.selectedInvoiceNumber !== ""
        ? customerData.selectedInvoiceNumber
        : "-"
    );
    setPurposeOfValuation(customerData.selectedawbNumber);
    setValuationDate(customerData.selectedDate);
    setValuationReport(valuationDetails);
    setRealJewellery(realJewelleryData);
    setImitationJewellery(imitationJewelleryData);
  }, []);

  useEffect(() => {
    const beforeUnloadHandler = () => {
      localStorage.removeItem("CustDetails");
      localStorage.removeItem("tableData");
      localStorage.removeItem("CertificateNumber");
      localStorage.removeItem("Updated");
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  const handleSubmit = async () => {
    setIsLoader(true);

    try {
      if (updated) {
        setShowQR(true);
        const contentPage = document.getElementById("content");
        const content1Page = document.getElementById("content1");
        const content2Page = document.getElementById("content2");
        let content1Canvas;
        let content2Canvas;

        function setFixedWidth(element, width) {
          element.style.width = width;
        }
        function revertWidth(element) {
          element.style.width = "";
        }

        const fixedWidth = "1024px";

        setFixedWidth(contentPage, fixedWidth);
        const contentCanvas = await html2canvas(contentPage);
        revertWidth(contentPage);

        if (realJewellery && realJewellery.length !== 0) {
          setFixedWidth(content1Page, fixedWidth);
          content1Canvas = await html2canvas(content1Page);
          revertWidth(content1Page);
        }
        if (imitationJewellery && imitationJewellery.length !== 0) {
          setFixedWidth(content2Page, fixedWidth);
          content2Canvas = await html2canvas(content2Page);
          revertWidth(content2Page);
        }

        const pdf = new jsPDF("p", "mm", "a4", true);

        function getHeight(canvas, width) {
          const aspectRatio = canvas.height / canvas.width;
          return width * aspectRatio;
        }

        // const content1Height = getHeight(content1Canvas, 445);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgHeight = contentCanvas.height;
        const imgWidth = contentCanvas.width;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgx = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;

        pdf.addImage(
          contentCanvas.toDataURL("image/png"),
          "PNG",
          imgx,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );

        if (
          (realJewellery && realJewellery.length !== 0) ||
          (imitationJewellery && imitationJewellery.length !== 0)
        ) {
          if (realJewellery && realJewellery.length !== 0) {
            pdf.addPage();
            pdf.addImage(
              content1Canvas.toDataURL("image/png"),
              "PNG",
              imgx,
              imgY,
              imgWidth * ratio,
              imgHeight * ratio,
              null,
              "FAST",
              0
            );
          }
          if (imitationJewellery && imitationJewellery.length !== 0) {
            pdf.addPage();
            pdf.addImage(
              content2Canvas.toDataURL("image/png"),
              "PNG",
              imgx,
              imgY,
              imgWidth * ratio,
              imgHeight * ratio,
              null,
              "FAST",
              0
            );
          }
        }

        const pdfBlob = pdf.output("blob");
        const response = await getUploadFileURL(type);
        const fileUploadResult = await fetch(response.url, {
          method: "PUT",
          body: pdfBlob,
        });
        const { ok = false, status = null } = fileUploadResult;
        if (ok && status == "200") {
          const res = await apiRequest(
            "POST",
            `valuationUpdateData`,
            {
              pdf: response.Key ? response.Key : null,
              certificateNo: certificateNumber,
              awbNo: awbNumber !== "-" ? awbNumber : "",
              invoiceNo: invoiceNumber !== "-" ? invoiceNumber : "",
              purposeOfValuation: customerData.selectedPurpose,
              valuerId: loggedinId,
              jewellerId: jewellerId,
              customerId: customerData?.selectedCustomer,
              dateOfValuation: valuationDate,
              valuationDetails: valuationDetails,
              loggedinId,
            },
            true,
            false
          );

          if (res.status === 200) {
            toast.success(res.message);
            pdf.save(`${certificateNumber}.pdf`);
            setIsLoader(false);
            window.location.href = "/valuationHistory";
          } else {
            setIsLoader(false);
            toast.error(res.message);
          }
        }
      } else {
        const res = await apiRequest(
          "POST",
          `valuationSaveData`,
          {
            certificateNo: certificateNumber,
            awbNo: awbNumber !== "-" ? awbNumber : "",
            invoiceNo: invoiceNumber !== "-" ? invoiceNumber : "",
            purposeOfValuation: customerData.selectedPurpose,
            valuerId: loggedinId,
            jewellerId: jewellerId,
            roleId: roleId,
            customerId: customerData?.selectedCustomer,
            dateOfValuation: valuationDate,
            valuationDetails: valuationDetails,
            loggedinId,
          },
          true,
          false
        );

        if (res.status === 200) {
          setIsLoader(false);
          toast.success(res.message);
          window.location.reload();
        } else {
          setIsLoader(false);
          toast.error(res.message);
        }
      }
    } catch (error) {
      setIsLoader(false);
      toast.error("Try after sometime");
    }
  };

  const getUploadFileURL = (type) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await apiRequest(
          "GET",
          `getAWSFileUploadURL?type=${type}&screen=MyValuation`,
          null,
          true,
          false
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <div>
      <div id="content">
        <div className="HomepagesectionB" style={{ overflowX: "auto" }}>
          <div className="HomesectionA"></div>
          <div className="Apart1">
            <div className="cont">
              <p>CERTIFICATE NO : </p>
              <input
                className="inputfields"
                type="text"
                value={certificateNumber}
                readOnly
              />
            </div>
            <div className="cont">
              <p>A.W.B. NO :</p>
              <input
                className="inputfields"
                type="text"
                value={awbNumber}
                readOnly
              />
            </div>
            <div className="cont">
              <p>INVOICE NO : </p>
              <input
                className="inputfields"
                type="text"
                value={invoiceNumber}
                readOnly
              />
            </div>
          </div>
          <div className="secBhead">
            <center style={{ marginLeft: "0%" }}>
              <h3 className="f8">FORM 08</h3>
              <br />
              {/* <p>See Rule 8D</p> */}
              <p>Report of Valuation of Jewellery</p>
              <br />
            </center>
          </div>
          <section>
            <table className="table report-table">
              <tbody>
                <tr className="tr-1">
                  <th className="thruser">Name of Registered valuer</th>
                  <th className="rno">Registration No.</th>
                </tr>
                {jewellerId === "" ? (
                  <tr>
                    <td className="thruser">
                      {valuerName + ", " + userData?.ValuerName}
                    </td>
                    <td>{userData?.RegistrationNumber}</td>
                  </tr>
                ) : (
                  <tr>
                    <td className="thruser">
                      {jewellerList[0]?.JewellerName +
                        ", " +
                        jewellerList[0]?.Address}
                    </td>
                    <td>{jewellerList[0]?.RegistrationNumber}</td>
                  </tr>
                )}

                <tr>
                  <td style={{ textAlign: "left" }}>
                    1. Purpose for which valuation is made.{" "}
                  </td>
                  <td className="wbg">{purposeOfValuation}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    2. Date as on which valuation is made.
                  </td>
                  <td className="wbg">{getDisplayDate(valuationDate)}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    3. Name Of Owner(s) the Jewellery{" "}
                  </td>
                  <td className="wbg">
                    {customerDataId[0]?.FirstName +
                      " " +
                      customerDataId[0]?.LastName}
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    4. If asset is under joint ownership/ co-ownership
                  </td>
                  <td>
                    <p>
                      <b>As per list attached</b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    5. Description of each item of jewellery.
                  </td>
                  <td>" "</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    6. Total gross weight of each item of jewellery.
                  </td>
                  <td>" "</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    7. Net Weight of precious metal,such as gold, silver,
                    Platinum etc. in each item of jewellery.
                  </td>
                  <td>" "</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    8. Description of each precious or semi precious stone.
                    Whether or not set in any ornament, wearning appeal etc.{" "}
                  </td>
                  <td>" "</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    9. Weight of each such precious or semi precious stone in
                    carats.
                  </td>
                  <td>" "</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    10. Value of each precious or semi precious stone and the
                    total Value Of such Stones.
                  </td>
                  <td>" "</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    11. Value Of the precious metal content in all the item of
                    Jewellery.
                  </td>
                  <td>" "</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    12. Total Value of jewellery.[ The valuer should discuss the
                    Special features, if any of the jewellery, such as its
                    antique aethetic value, aesthic value etc.]
                  </td>
                  <td>---</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    13. I hereby declare that <br />
                    (a) The information Furnished above is true and correct to
                    best of my knowledge and belief. <br />
                    (b) I have no direct or indirect interest in the jewellery
                    Valued. <br />
                    (c) I have personally inspected the jewellery. <br />
                    (d) I have not been convicted of any offence and sentenced
                    to a term of imprisonment.{" "}
                  </td>
                  <td>
                    <p style={{ paddingTop: "15%" }}>
                      *This is a computer-generated document. No signature is
                      required.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
      {/* <Valuationreport reportData={propsData} /> */}

      {realJewellery && realJewellery.length > 0 && (
        <div id="content1">
          <div className="HomepagesectionB" style={{ overflowX: "auto" }}>
            <div className="VR">
              <center
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <h1 className="head12">VALUATION REPORT</h1>&nbsp;
                <h2 className="head12" style={{}}>
                  (For Real Jewellery){" "}
                </h2>
              </center>
              <table>
                <tbody>
                  <tr>
                    <th colspan={2}> NAME :-</th>
                    <th
                      style={{ width: "48rem" }}
                      className="name-th"
                      colspan={7}
                    >
                      {customerDataId[0]?.FirstName +
                        " " +
                        customerDataId[0]?.LastName}
                    </th>
                  </tr>
                  <tr>
                    <td colspan={2}>ADDRESS:-</td>
                    <td colspan={7}>
                      {customerDataId[0]?.Address1 +
                        ", " +
                        customerDataId[0]?.City +
                        ", " +
                        customerDataId[0]?.State +
                        "-" +
                        customerDataId[0]?.PinCode +
                        ", " +
                        customerDataId[0]?.Country +
                        ", "}
                      Mobile No. {customerDataId[0]?.MobileNumber}
                    </td>
                  </tr>
                  <tr>
                    <th>Sr. No</th>
                    <th> DESCRIPTION</th>
                    <th>QTY.</th>
                    <th>PURITY</th>
                    <th>GROSS</th>
                    <th>NET WT.(Gms) </th>
                    <th>CT. ST.</th>
                    <th>RATE</th>
                    <th>AMOUNT</th>
                  </tr>
                  {realJewellery.map((item, index) => {
                    const ornament = ornamentList?.find(
                      (ornament) => ornament.OrnamentsMasterId == item.Ornament
                    );
                    const amount = item.Net * item.Rate;
                    totalAmountReal += amount;
                    totalQtyReal += parseInt(item.Qty);
                    totalGrossReal += parseFloat(item.Gross);
                    totalNetReal += parseFloat(item.Net);
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: "right" }}>{index + 1}</td>
                        <td>{ornament?.OrnamentName}</td>
                        <td style={{ textAlign: "right" }}>{item.Qty}</td>
                        <td style={{ textAlign: "right" }}>{item.Purity}</td>
                        <td style={{ textAlign: "right" }}>{item.Gross}</td>
                        <td style={{ textAlign: "right" }}>{item.Net}</td>
                        <td style={{ textAlign: "right" }}>{item.CTST}</td>
                        <td style={{ textAlign: "right" }}>{item.Rate}</td>
                        <td style={{ textAlign: "right" }}>
                          {amount.toFixed(0)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td>TOTAL</td>
                    <td style={{ textAlign: "right" }}>{totalQtyReal}</td>
                    <td></td>
                    <th style={{ textAlign: "right" }}>
                      {totalGrossReal.toFixed(0)}
                    </th>
                    <th style={{ textAlign: "right" }}>
                      {totalNetReal.toFixed(0)}
                    </th>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      ₹ {totalAmountReal.toFixed(0)}
                    </td>
                  </tr>
                  <tr>
                    <td colspan={2}>In Words:-</td>
                    <td colspan={7}>
                      {toWords
                        .convert(totalAmountReal, { currency: true })
                        .toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      Valuation based on Market Value as on{" "}
                      <b>{getDateMonthYear(valuationDate)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      <b>
                        The valuation was done for the jewellery as presented to
                        me at the time of valuation. Hence, the responsibility
                        of valuation is binded for the jewellery presented as on
                        date of valuation
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan={9}>
                      <b>
                        We herby declare that we have no direct or indirect
                        interest in the said Jewellery and all the information
                        here are true and correct to the best of our knowledge
                        and belief; which are based on the facts and information
                        provided by Client/Owner/Management.
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {imitationJewellery && imitationJewellery.length > 0 && (
        <div id="content2">
          <div className="HomepagesectionB" style={{ overflowX: "auto" }}>
            <div className="VR">
              <center
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <h1 className="head12">VALUATION REPORT</h1>&nbsp;
                <h2 className="head12">(For Imitation) </h2>
              </center>
              <center>
                <table>
                  <tr>
                    <th colSpan={1}>NAME :-</th>
                    <th colSpan={8}>
                      {customerDataId[0]?.FirstName +
                        " " +
                        customerDataId[0]?.LastName}
                    </th>
                  </tr>
                  <tr>
                    <td colSpan={1}>ADDRESS:-</td>
                    <td colSpan={8}>
                      {customerDataId[0]?.Address1 +
                        ", " +
                        customerDataId[0]?.City +
                        ", " +
                        customerDataId[0]?.State +
                        "-" +
                        customerDataId[0]?.PinCode +
                        ", " +
                        customerDataId[0]?.Country +
                        ", "}
                      Mobile No. {customerDataId[0]?.MobileNumber}
                    </td>
                  </tr>
                  <tr>
                    <th>Sr. No</th>
                    <th> DESCRIPTION</th>
                    <th>QTY.</th>
                    <th>PURITY</th>
                    <th>GROSS</th>
                    <th>NET </th>
                    <th>CT. ST.</th>
                    <th>RATE</th>
                    <th>AMOUNT</th>
                  </tr>
                  {imitationJewellery.map((item, index) => {
                    const ornament = ornamentList?.find(
                      (ornament) => ornament.OrnamentsMasterId == item.Ornament
                    );
                    const amount = item.Qty * item.Rate;
                    totalAmountImitation += amount;
                    totalQtyImit += parseInt(item.Qty, 10);
                    totalGrossImit += parseFloat(item.Gross);
                    totalNetImit += parseFloat(item.Net);
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: "right" }}>{index + 1}</td>
                        <td>{ornament?.OrnamentName}</td>
                        <td style={{ textAlign: "right" }}>{item.Qty}</td>
                        <td style={{ textAlign: "right" }}>{item.Purity}</td>
                        <td style={{ textAlign: "right" }}>{item.Gross}</td>
                        <td style={{ textAlign: "right" }}>{item.Net}</td>
                        <td style={{ textAlign: "right" }}>{item.CTST}</td>
                        <td style={{ textAlign: "right" }}>{item.Rate}</td>
                        <td style={{ textAlign: "right" }}>
                          {amount.toFixed(0)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td className="note">
                      <b>
                        NOTE:- ALL ABOVE JEWELLERYS ARE NOT PRECIOUS METAL
                        <br />
                        WE DO NOT FOUND ANY GOLD ,SILVER OR PRECIOUS STONE.
                        <br />
                        ALL ARE IMITATION JEWELLERY
                        <br />
                        AS PER PARTY'S INVOICE VALUE RS:{" "}
                        {totalAmountImitation.toFixed(0)} /-
                      </b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <th></th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>TOTAL</td>
                    <td style={{ textAlign: "right" }}>{totalQtyImit}</td>
                    <td></td>
                    <th style={{ textAlign: "right" }}>
                      {totalGrossImit.toFixed(0)}
                    </th>
                    <th style={{ textAlign: "right" }}>
                      {totalNetImit.toFixed(0)}
                    </th>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      ₹ {totalAmountImitation.toFixed(0)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>In Words:-</td>
                    <td colSpan={8}>
                      {toWords
                        .convert(totalAmountImitation, { currency: true })
                        .toUpperCase()}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={9}>
                      Valuation based on Market Value as on{" "}
                      <b>{getDateMonthYear(valuationDate)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={9}>
                      <b>
                        The valuation was done for the jewellery as presented to
                        me at the time of valuation. Hence, the responsibility
                        of valuation is binded for the jewellery presented as on
                        date of valuation
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={9}>
                      <b>
                        We herby declare that we have no direct or indirect
                        interest in the said Jewellery and all the information
                        here are true and correct to the best of our knowledge
                        and belief; which are based on the facts and information
                        provided by Client/Owner/Management.
                      </b>
                    </td>
                  </tr>
                </table>
              </center>
            </div>
          </div>
        </div>
      )}
      <div className="button-container">
        <button className="savepdfbtn" onClick={handleSubmit} type="primary">
          {updated ? "Print" : "Draft"}
        </button>
      </div>
      {isLoader && <Loader />}
    </div>
  );
};
export default ReportVJ;
