import React, { useEffect, useState } from 'react';
import "../../CSS/banner.css";
import Sidebar from "../SettingsPage/Sidebar";
import {
    Divider,
    Grid,
} from "@material-ui/core";
import apiRequest from '../../APICall';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as yup from "yup";
import { getDBFormateDate, getDisplayDate } from '../../DateTimeUtils';
import MySelect from '../../Components/AutoComplete';
import { months, getYears } from '../../Components/MonthYearCombo';
import DatePicker from '../../Components/DatePicker';
import MaterialTable from 'material-table';
import { GridIcons, options, style } from '../../Components/GridConfig';
import Loader from '../../Components/Loader';

const CarateRate = () => {

    const currentdate = new Date();
    const currentYear = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();
    const [isShown, setIsShown] = useState(false);
    const [add, setAdd] = useState(false);
    const [dailyprizelist, setDailyprizelist] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [yearList, setYearList] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [fromDate, setFromDate] = useState(currentdate);
    const [toDate, setToDate] = useState(currentdate);
    const [isLoader, setIsLoader] = useState(false);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const loggedinId = userData ? userData.ValuerMasterId : "";
    const roleId = userData ? userData.ValuerRoleId : "";
    const jewellerId = userData ? userData.JewellerId : "";

    const getdailyprizedetails = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("post", `getdailyprizedetails`, {
                month: selectedMonth ? selectedMonth : currentMonthIndex + 1,
                year: selectedYear ? selectedYear : currentYear.toString(),
                loggedinId,
                roleId,
                jewellerId
            }, true, false);
            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
                Date: getDisplayDate(item.Date)
            }));
            setIsLoader(false);
            setIsShown(true);
            setAdd(false)
            setDailyprizelist(modifiedRes);
        } catch (error) {
            setIsLoader(false);
            console.log(error)
            setDailyprizelist([]);
            toast.error("Try after sometime");
        }
    };

    useEffect(() => {
        setMonthList(months);
        setYearList(getYears());
        setSelectedMonth(currentMonthIndex + 1);
        setSelectedYear(currentYear.toString());
    }, []);
    useEffect(() => {
        getdailyprizedetails();
    }, []);

    const initialValues = {
        prize: "",
        fromDate: currentdate,
        toDate: currentdate,
    };

    const validationSchema = yup.object({
        prize: yup
            .string()
            .required("Please enter Prize")
            .test('is-non-decimal', 'Prize should be a whole number', function (value) {
                return !/\./.test(value) && /^\d+$/.test(value);
            }),
        fromDate: yup
            .string()
            .required("Please select from date"),
        toDate: yup
            .string()
            .required("Please select to date"),
    });

    const { values, handleSubmit, handleChange, errors, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setIsLoader(true);
            setIsShown(false);
            try {
                apiRequest("POST", `updatedailyprize`,
                    {
                        prize: values.prize,
                        fromDate: getDBFormateDate(values.fromDate),
                        toDate: getDBFormateDate(values.toDate),
                        loggedinId,
                        roleId,
                        jewellerId
                    },
                    true, false)
                    .then((res) => {
                        if (res.status === 200) {
                            toast.success(res.message);
                            resetForm();
                            setIsLoader(false);
                            getdailyprizedetails();
                        } else {
                            setIsLoader(false);
                            toast.error(res.message);
                        }
                    })
                    .catch(function (error) {
                        setIsLoader(false);
                        toast.error("Try after sometime")
                    });
            } catch (error) {
                setIsLoader(false);
                toast.error("Try after sometime")
            }
        }
    });

    const columns = [
        {
            title: "Sr.No",
            field: "index"
        },
        {
            title: "DATE",
            field: "Date",
        },
        {
            title: "Rate",
            field: "Prize",
        },
        {
            title: "Created By",
            field: "ValuerName",
        },
    ]

    const getMonthName = (monthIndex) => {
        return months.find(month => month.value === monthIndex).label;
    };

    return (
        <>
            <div className="settingsmaindiv">

                <section className="set-secBs">
                    <div className='banner-container'>
                        <div className='isInline'>
                        <h1 style={{ display:"flex" , alignItems:"center" }}><Sidebar />24 Carat Rate</h1>
                            <button className="add-ornament-btn"
                                onClick={() => {
                                    setIsShown(false)
                                    setAdd(true);
                                }}>
                                Add New
                            </button>
                        </div>
                        <Grid className='filterSearch' container spacing={3} style={{ alignItems: "center" }}>
                            <Grid item xs={12} md={4}>
                                <label>Month</label>
                                <MySelect
                                    options={monthList}
                                    placeholder="Select Month"
                                    value={selectedMonth}
                                    onChange={(value) => {
                                        setSelectedMonth(value);
                                        setIsShown(false);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <label>Year</label>
                                <MySelect
                                    options={yearList}
                                    placeholder="Select Year"
                                    value={selectedYear}
                                    onChange={(value) => {
                                        setSelectedYear(value);
                                        setIsShown(false);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={1}>
                                <button className="add-ornament-btn-1"
                                    onClick={() => {
                                        getdailyprizedetails();
                                    }}
                                >Go</button>
                            </Grid>
                        </Grid><br />
                        <Divider/><br/>
                        {isShown && (
                            <section className="tablecontainer">
                                <MaterialTable
                                    icons={GridIcons}
                                    title={`List of 24 Carat Rate - ${getMonthName(selectedMonth)} ${selectedYear}`}
                                    columns={columns}
                                    data={dailyprizelist}
                                    options={options}
                                    style={style}
                                />
                            </section>
                        )}
                        {add && (
                            <form onSubmit={handleSubmit} style={{ border: "none" }}>
                                <div className="banner-card">
                                    <Grid container spacing={3} style={{ alignItems: "center" }}>
                                        <Grid item xs={12} md={4}>
                                            <label>From Date: *</label>
                                            <DatePicker
                                                id="fromDate"
                                                label={"From Date"}
                                                defaultValue={values.fromDate}
                                                onChange={(e) => {
                                                    handleChange({ target: { name: "fromDate", value: e } });
                                                }}
                                            />
                                            {errors.fromDate && (<div className="errorcss">{errors.fromDate}</div>)}
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <label>To Date: *</label>
                                            <DatePicker
                                                id="toDate"
                                                label={"To Date"}
                                                minDate={values.fromDate}
                                                defaultValue={values.toDate}
                                                onChange={(e) => {
                                                    handleChange({ target: { name: "toDate", value: e } });
                                                }}
                                            />
                                            {errors.toDate && (<div className="errorcss">{errors.toDate}</div>)}
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <label>Rate(10gm): *</label>
                                            <input
                                                type="number"
                                                name="prize"
                                                id="prize"
                                                placeholder='Rate'
                                                className='inputFieldGrid'
                                                value={values.prize}
                                                onChange={handleChange}
                                            />
                                            {errors.prize && (<div className="errorcss">{errors.prize}</div>)}
                                        </Grid>
                                    </Grid>

                                    <div className='isInline-1'>
                                        <button onClick={() => {
                                            setAdd(false);
                                            resetForm();
                                        }}>
                                            Cancel
                                        </button>
                                        <button type="submit" >
                                            Add Prize
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </section>
            </div>
            {isLoader && <Loader/>}
        </>
    );
};

export default CarateRate;
