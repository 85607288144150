import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import "../CSS/home.css";
import {
  GridIcons,
  options,
  style,
  actionColumnStyle,
} from "../Components/GridConfig";
import { Edit, Home } from "@material-ui/icons";
import apiRequest from "../APICall";
import toast from "react-hot-toast";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { getDisplayDate } from "../DateTimeUtils";
import { BiSolidDownload } from "react-icons/bi";
import QRCode from "react-qr-code";
import MaterialTable from "material-table";
import Loader from "../Components/Loader";
const { getImagePath } = require("../config.json");


const Slider = ({ banners }) => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className="slider-container" style={{ textDecoration: "none" }}>
      <a
        className="link"
        href={`http://${banners[currentBannerIndex].HyperLink}`} // Add 'http://' or 'https://' based on the actual protocol of the URL
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={banners[currentBannerIndex].Image}
          className="card-image"
          alt="Card"
        />
        <div className="card-content">
          <h2 className="card-heading">
            {banners[currentBannerIndex].Heading}
          </h2>
          <p className="card-text">{banners[currentBannerIndex].Paragraph}</p>
        </div>
      </a>
    </div>
  );
};

const Firstpage = () => {
  const [certificateNumber, setCertificateNumber] = useState("");
  const [isBanner, setIsBanner] = useState(true);
  const [data, setData] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [bannerList1, setBannerList1] = useState([]);
  const [bannerList2, setBannerList2] = useState([]);
  const [bannerList3, setBannerList3] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [blogData, setBlogData] = useState([]);

  const getApiData = async () => {
    return new Promise((resolve, reject) => {
      apiRequest("GET", "blogs/homeGetblog", null, false, false)
        .then((res) => {
          resolve(res);
          setBlogData(res.data);
        })
        .catch((error) => {
          console.error("Error calling Blog API:", error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    getApiData();
  }, []);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const res = await apiRequest(
        "GET",
        `checkCertificateNumber?certificateNumber=${certificateNumber}`,
        null,
        false,
        false
      );
      if (res.isSuccess) {
        const data = res.list.map((item, index) => ({
          ...item,
          index: index + 1,
          link: getImagePath.ImagePath + item.AWSLink,
        }));
        setData(data);
        setIsShown(true);
        setIsBanner(false);
      } else if (res.isSuccess === false) {
        toast.error(res.message);
      } else {
        toast.error("Try after sometime");
      }
    } catch (error) {
      toast.error("Try after sometime");
    }
  };

  // useEffect(() => {
  //   bannerHomeGetData();
  // }, []);

  const bannerHomeGetData = async () => {
    setIsLoading(true);
    try {
      const res = await apiRequest(
        "GET",
        `bannerHomeGetData`,
        null,
        false,
        false
      );
      const banner1Data = res.filter(
        (item) => item.BannerNumber === "Banner 1"
      );
      const banner2Data = res.filter(
        (item) => item.BannerNumber === "Banner 2"
      );
      const banner3Data = res.filter(
        (item) => item.BannerNumber === "Banner 3"
      );
      setIsLoading(false);
      setBannerList1(banner1Data);
      setBannerList2(banner2Data);
      setBannerList3(banner3Data);
    } catch (error) {
      setIsLoading(false);
      toast.error("Try after sometime");
    }
  };

  const columns = [
    {
      title: "Sr.No",
      field: "index",
    },
    {
      title: "Certificate Number",
      field: "CertificateNumber",
    },
    {
      title: "Purpose Of Valuation",
      field: "PurposeOfValuation",
    },
    {
      title: "Date Of Valuation",
      field: "DateOfValuation",
    },
    {
      title: "Download",
      ...actionColumnStyle,
      render: ({ tableData: { id } }) => {
        return (
          <div className="table-edit-controls">
            <Tooltip title="Edit">
              <a
                href={data[id].link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "var(--font)", cursor: "pointer" }}
              >
                <BiSolidDownload />
              </a>
            </Tooltip>
          </div>
        );
      },
      printable: false,
    },
    {
      title: "QR Code",
      ...actionColumnStyle,
      render: ({ tableData: { id } }) => {
        return (
          <div className="table-edit-controls">
            <Tooltip title="Edit">
              <QRCode value={data[id].link} style={{ height: 70 }} />
            </Tooltip>
          </div>
        );
      },
      printable: false,
    },
  ];

  return (
    <div className="homeMain">
      <div className="search-bar-main">
        <p className="mainhead"></p>
        <div className="formdetails-1">
          <div className="search-bar-container">
            <div className="certificate-mobile">
              <input
                className="search-input"
                type="text"
                placeholder="Certificate Number Or Mobile Number"
                id="certificateNumber"
                value={certificateNumber}
                onChange={(event) => {
                  setCertificateNumber(event.target.value);
                }}
              />
            </div>
            <button
              type="submit"
              className="search-button"
              onClick={() => {
                if (certificateNumber === "") {
                  toast.error(
                    "Please add certificate number or mobile  number"
                  );
                } else {
                  setIsShown(false);
                  setIsBanner(true);
                  handleSubmit();
                }
              }}
            >
              Find Certificate
            </button>
          </div>
          {/* <div >
            <button className="check-valuer" onClick={()=>navigate("/checkvaluer")}>
            Find Valuer
            </button>
           
          </div> */}
        </div>
      </div>

      {isBanner && !isShown && (
        <>
          <center className="ad"></center>
          <div className="card-container">
            {bannerList1.length > 1 ? (
              <div className="card">
                <Slider banners={bannerList1} />
              </div>
            ) : (
              bannerList1.map((banner, index) => (
                <div className="card" key={index}>
                  <Slider banners={[banner]} />
                </div>
              ))
            )}
            {bannerList2.length > 1 ? (
              <div className="card">
                <Slider banners={bannerList2} />
              </div>
            ) : (
              bannerList2.map((banner, index) => (
                <div className="card" key={index}>
                  <Slider banners={[banner]} />
                </div>
              ))
            )}
            {bannerList3.length > 1 ? (
              <div className="card">
                <Slider banners={bannerList3} />
              </div>
            ) : (
              bannerList3.map((banner, index) => (
                <div className="card" key={index}>
                  <Slider banners={[banner]} />
                </div>
              ))
            )}
          </div>
        </>
      )}

      {/* For Ads */}
      {isBanner && !isShown && <></>}

      {/* For Blogs */}
      {!isShown && (
        <div>
          <div className="home-blog-card-container">
            <div className="home-blog-card-wrapper">
              {blogData.map((item) => (
                <div key={item.BlogId} className="home-blog-card">
                  <Link to={"blog/" + item.Permalink}>
                    <h2>{item.Title}</h2>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {isShown && !isBanner && (
        <>
          <center>
            <div className="homeTable">
              <section className="tablecontainer">
                <MaterialTable
                  icons={GridIcons}
                  title={`List of valuations`}
                  columns={columns}
                  data={data}
                  options={options}
                  style={style}
                />
              </section>
            </div>
          </center>
        </>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default Firstpage;

const HomeBlog = () => {
  const { permalink } = useParams();
  const [blogData, setBlogData] = useState([]);

  const getApiData = async () => {
    try {
      const res = await apiRequest(
        "GET",
        `user-blog-post/${permalink}`,
        null,
        false,
        false
      );
      setBlogData(res.data);
    } catch (error) {
      console.error("Error calling Blog API:", error);
    } finally {
    }
  };

  useEffect(() => {
    getApiData();
  }, [permalink]);

  return (
    <div>
      <div className="home-single-blog">
        <h1 className="single-blog-title">{blogData.Title}</h1>
        <p>Created Date: {blogData.CreatedDate}</p>
        <br/>
        <p>{blogData.Description}</p>
        <br/>
        <div className="home-blog-post-image">
                            <img src={getImagePath.ImagePath + blogData.FeatureImage} alt={blogData.Title} />
                        </div>

        <div>{blogData.Content && typeof blogData.Content === 'string' ? parse(blogData.Content) : null}</div>
      </div>
    </div>
  );
};

export { HomeBlog };
