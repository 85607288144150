import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import "../CSS/stepper.css";

import Custodetails from "../Pages/CustomerDetails";
import Valuationdetails from "../Pages/ValuationDetails";
import Valuationreport from "../Pages/ValuationReport";
import toast from "react-hot-toast";

const steps = ["Customer Details", "Valuation Details", "Generate Report"];

function ValuationSteps() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep == 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      let localData = JSON.parse(localStorage.getItem("tableData"));
      if (localData == null || localData.length == 0) {
        setActiveStep(activeStep);
        toast.error("Please add atleast one record");
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCustDetails = (data) => {
    setCustDetails(data);
  };

  const handleValDetails = (data) => {
    setValDetails(data);
  };

  // states for pages
  const [custDetails, setCustDetails] = useState([]);

  const [valDetails, setValDetails] = useState([]);

  return (
    <div>
      <Stepper activeStep={activeStep} className="stepper">
        {steps.map((label) => (
          <Step key={label} className="stepper-step">
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <StepOne
          handleNext={handleNext}
          handleCustDetails={handleCustDetails}
          custDetails={custDetails}
        />
      )}
      {activeStep === 1 && (
        <StepTwo handleValDetails={handleValDetails} valDetails={valDetails} />
      )}
      {activeStep === 2 && (
        <StepThree custDetails={custDetails} valDetails={valDetails} />
      )}
      <div className="navigation-button-container">
        {activeStep > 0 && (
          <button className="cus-det-save" onClick={handleBack}>
            Back
          </button>
        )}
        &nbsp;&nbsp;
        {activeStep !== 0 && activeStep !== steps.length - 1 && (
          <button className="cus-det-save" onClick={handleNext}>
            Next
          </button>
        )}
        <br />
        <br />
      </div>
    </div>
  );
}

function StepOne({ handleNext, handleCustDetails }) {
  return (
    <div>
      <Custodetails
        handleNext={handleNext}
        handleCustDetails={handleCustDetails}
      />
    </div>
  );
}

function StepTwo({ setValDetails, handleValDetails }) {
  return (
    <div>
      <Valuationdetails
        setValDetails={setValDetails}
        handleValDetails={handleValDetails}
      />
    </div>
  );
}

function StepThree() {
  // return <div><ReportVJ /></div>;
  return (
    <div>
      <Valuationreport setDownload={true} />
    </div>
  );
}

export default ValuationSteps;
