import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Drawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

const Sidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const loggedinId = userData ? userData.ValuerMasterId : "";
  const roleId = userData ? userData.ValuerRoleId : "";

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <div style={{ width: 250 }}>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
          <List>
            <ListItem>
              <h3 className="sidebar-head">Settings</h3>
            </ListItem>
            <ListItem button component={Link} to="/valuer" onClick={toggleDrawer}>
              <ListItemText primary="Manage Valuer" />
            </ListItem>
            <ListItem button component={Link} to="/dailycaratrate" onClick={toggleDrawer}>
              <ListItemText primary="Carat Rate" />
            </ListItem>
            {roleId && roleId === 1 && (
              <>
                <ListItem button component={Link} to="/jeweller" onClick={toggleDrawer}>
                  <ListItemText primary="Manage Jeweller" />
                </ListItem>
                <ListItem button component={Link} to="/manageadmin" onClick={toggleDrawer}>
                  <ListItemText primary="Manage Admin" />
                </ListItem>
                <ListItem button component={Link} to="/ornaments" onClick={toggleDrawer}>
                  <ListItemText primary="Manage Ornaments" />
                </ListItem>
                <ListItem button component={Link} to="/banner" onClick={toggleDrawer}>
                  <ListItemText primary="Manage Banner" />
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
