import React, { useState, useEffect } from 'react';
import "../../CSS/banner.css"
import * as yup from "yup";
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import apiRequest from "../../APICall";
import Sidebar from "../SettingsPage/Sidebar";
import { getDisplayDate, getMonthDateYear } from '../../DateTimeUtils';
import { BiEditAlt, BiTrash } from 'react-icons/bi';
import { Divider, Grid, Tooltip } from '@material-ui/core';
import MaterialTable from 'material-table';
import { GridIcons, actionColumnStyle, options, style } from '../../Components/GridConfig';
import Loader from '../../Components/Loader';

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ManageJewellerPage = () => {

    const [jewellerList, setJewellerList] = useState([]);
    const [isShown, setIsShown] = useState(false);
    const [isTableShown, setIsTableShown] = useState(true);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const loggedinId = userData ? userData.ValuerMasterId : "";
    const roleId = userData ? userData.ValuerRoleId : "";

    useEffect(() => {
        jewellerGetData();
    }, []);

    const jewellerGetData = async () => {
        setIsLoader(true);
        try {
            const res = await apiRequest("GET", `jewellerGetData`, null, true, false);
            const modifiedRes = res.map((item, index) => ({
                ...item,
                index: index + 1,
            }));
            setIsLoader(false);
            setJewellerList(modifiedRes);
        } catch (error) {
            setIsLoader(false);
            toast.error("Try after sometime");
        }
    };

    const initialValues = {
        jewellerName: "",
        email: "",
        address: "",
        registration: "",
        mobile: "",
        code: "",
    };

    const validationSchema = yup.object({
        jewellerName: yup
            .string()
            .required("Please add Admin name"),
        email: yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        address: yup.string()
            .required("Please enter email"),
        code: yup.string()
            .required("Please enter code")
            .min(4, "Code should be 4-8 characters")
            .max(8, "Code should be 4-8 characters"),
        registration: yup.string()
            .required("Please enter registration number")
            .min(4, "Registration Number should be 4-8 characters")
            .max(8, "Registration Number should be 4-8 characters"),
        mobile: yup.string()
            .required("Please enter Mobile Number")
            .min(10, "Mobile Number should be 10 digit.")
            .max(10, "Mobile Number should be 10 digit.")
            .matches(phoneRegExp, "Phone number is not valid"),
    });

    const { values, handleSubmit, handleChange, errors, resetForm, setValues } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setIsLoader(true);
            try {
                if (selectedBanner) {
                    apiRequest("POST", `updatejewellerdata`,
                        {
                            jewellerName: values.jewellerName,
                            email: values.email,
                            address: values.address,
                            registrationNo: values.registration,
                            code: values.code,
                            mobile: values.mobile,
                            jewellerMasterId: selectedBanner.JewellerMasterId,
                            loggedinId
                        },
                        true, false)
                        .then((res) => {
                            if (res.status === 200) {
                                toast.success(res.message);
                                jewellerGetData();
                                resetForm();
                                setIsShown(false);
                                setSelectedBanner(null);
                                setIsLoader(false);
                            } else {
                                setIsLoader(false);
                                toast.error(res.message);
                            }
                        })
                        .catch(function (error) {
                            setIsLoader(false);
                            toast.error("Try after sometime")
                        });
                } else {
                    apiRequest("POST", `addjewellerdata`,
                        {
                            jewellerName: values.jewellerName,
                            email: values.email,
                            address: values.address,
                            registrationNo: values.registration,
                            code: values.code,
                            mobile: values.mobile,
                            loggedinId
                        },
                        true, false)
                        .then((res) => {
                            if (res.status === 200) {
                                toast.success(res.message);
                                jewellerGetData();
                                resetForm();
                                setIsShown(false)
                                setIsLoader(false);
                            } else if (res.status === 400) {
                                setIsLoader(false);
                                toast.error(res.message);
                            } else {
                                setIsLoader(false);
                                toast.error(res.message);
                            }
                        })
                        .catch(function (error) {
                            setIsLoader(false);
                            toast.error("Try after sometime")
                        });
                }
            } catch (error) {
                setIsLoader(false);
                toast.error("Try after sometime")
            }
        }
    });

    const handleEdit = (banner) => {
        setSelectedBanner(banner);
        setIsShown(true);
        setValues({
            jewellerName: banner.JewellerName,
            mobile: banner.Mobile,
            email: banner.Email,
            code: banner.Code,
            registration: banner.RegistrationNumber,
            address: banner.Address,
        });
    };

    const columns = [
        {
            title: "Sr.No",
            field: "index"
        },
        {
            title: "JEWELLER NAME",
            field: "JewellerName",
        },
        {
            title: "MOBILE NUMBER",
            field: "Mobile",
        },
        {
            title: "EMAIL",
            field: "Email",
        },
        {
            title: "ADDRESS",
            field: "Address",
        },
        {
            title: "REGISTRATION NUMBER",
            field: "RegistrationNumber",
        },
        {
            title: "CREATED BY",
            field: "ValuerName",
        },
        {
            title: "Action",
            ...actionColumnStyle,
            render: ({ tableData: { id } }) => (
                <div className="table-edit-controls">
                    <Tooltip title="Click to edit" style={{ cursor: "pointer" }}>
                        <BiEditAlt onClick={() => handleEdit(jewellerList[id])} />
                    </Tooltip>
                </div>
            ),
            printable: false,
        },
    ]


    return (
        <>
            <div className="settingsmaindiv">


                <section className="set-secBs">
                    <div className='banner-container'>
                        <div className='isInline'>
                        <h1 style={{ display:"flex" ,textAlign: "center", alignItems:"center", paddingLeft:"1%" }}><Sidebar />Jeweller</h1>
                            <button className="add-ornament-btn"
                                onClick={() => {
                                    setIsShown(true);
                                    resetForm();
                                }}>
                                Add Jeweller
                            </button>
                        </div>
                        
                        <Divider/><br/>
                        {!isShown && isTableShown && (
                            <section className="tablecontainer">
                                <MaterialTable
                                    icons={GridIcons}
                                    title={`Jeweller List`}
                                    columns={columns}
                                    data={jewellerList}
                                    options={options}
                                    style={style}
                                />
                            </section>
                        )}
                        {isShown && (
                            <form onSubmit={handleSubmit} style={{ border: "none" }}>
                                <div className="banner-card">
                                    <Grid container spacing={3} style={{ alignItems: "center" }}>
                                        <Grid item xs={12} md={4}>
                                            <label>Jeweller Name: *</label>
                                            <input
                                                type="text"
                                                name="jewellerName"
                                                id="jewellerName"
                                                className='inputFieldGrid'
                                                placeholder='Jeweller Name'
                                                value={values.jewellerName}
                                                onChange={handleChange}
                                            />
                                            {errors.jewellerName && (<div className="errorcss">{errors.jewellerName}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label>Mobile Number: *</label>
                                            <input
                                                type="text"
                                                name="mobile"
                                                id="mobile"
                                                className='inputFieldGrid'
                                                placeholder='Mobile Number'
                                                value={values.mobile}
                                                onChange={handleChange}
                                            />
                                            {errors.mobile && (<div className="errorcss">{errors.mobile}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label>Email: *</label>
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder='Email'
                                                className='inputFieldGrid'
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email && (<div className="errorcss">{errors.email}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label>Address: *</label>
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                className='inputFieldGrid'
                                                placeholder='Address'
                                                value={values.address}
                                                onChange={handleChange}
                                            />
                                            {errors.address && (<div className="errorcss">{errors.address}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label>Code: *</label>
                                            <input
                                                type="text"
                                                name="code"
                                                id="code"
                                                placeholder='Code'
                                                className='inputFieldGrid'
                                                value={values.code}
                                                onChange={handleChange}
                                            />
                                            {errors.code && (<div className="errorcss">{errors.code}</div>)}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label>Registration Number: *</label>
                                            <input
                                                type="text"
                                                name="registration"
                                                id="registration"
                                                placeholder='Registration Number'
                                                className='inputFieldGrid'
                                                value={values.registration}
                                                onChange={handleChange}
                                            />
                                            {errors.registration && (<div className="errorcss">{errors.registration}</div>)}
                                        </Grid>
                                    </Grid>

                                    <div className='isInline-1'>
                                        <button onClick={() => {
                                            setIsShown(false);
                                            setSelectedBanner(null);
                                        }}>
                                            Cancel
                                        </button>
                                        <button type="submit" >
                                            {selectedBanner ? "Update Jeweller" : "Add Jeweller"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </section>
            </div>
            {isLoader && <Loader/>}
        </>
    );
};

export default ManageJewellerPage;
