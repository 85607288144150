import React, { useState } from "react";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import apiRequest from "../APICall";
import Loader from "../Components/Loader";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddCustomer = ({ setIsShown, fetchCustomerList  }) => {

  const [isLoader, setIsLoader] = useState(false);
  const initialValues = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("Please enter a first name"),

    lastName: yup.string().required("Please enter a last name"),

    mobileNumber: yup
      .string()
      .required("Please enter Mobile Number")
      .min(10, "Mobile Number should be 10 digit.")
      .max(10, "Mobile Number should be 10 digit.")
      .matches(phoneRegExp, "Phone number is not valid"),

    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),

    address: yup.string().required("Address is required"),

    city: yup.string().required("City is required"),

    state: yup.string().required("State is required"),

    country: yup.string().required("Country is required"),

    pincode: yup.string().required("pincode is required"),
  });

  const { values, handleSubmit, handleChange, errors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoader(true);
      try {
        const res = await apiRequest(
          "POST",
          "customerdetails",
          {
            firstName: values.firstName,
            lastName: values.lastName,
            mobileNumber: values.mobileNumber,
            email: values.email,
            address: values.address,
            city: values.city,
            state: values.state,
            country: values.country,
            pincode: values.pincode,
          },
          true,
          false
        );

        if (res.status === 200) {
          toast.success(res.message);
          fetchCustomerList();
          setIsShown(false);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          toast.error(res.message);
        }
      } catch (error) {
        setIsLoader(false);
        console.error(error);
        toast.error("Something Went Wrong");
      } finally {
        setIsLoader(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="formdetails" onSubmit={handleSubmit}>
      {/* <div>
                <div > */}
      <h3 style={{ color: "var(--font)" }}>
        Add New Customer{" "}
      </h3>

      <div className="form-group-1">
        <div className="input-container">
          <input
            className="forminput"
            id="firstName"
            type="text"
            name="firstName"
            placeholder="First Name"
            value={values.firstName}
            onChange={handleChange}
          />
          {errors.firstName && (
            <div className="errorcss">{errors.firstName}</div>
          )}
        </div>

        <div className="input-container">
          <input
            className="forminput"
            id="lastName"
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={values.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <div className="errorcss">{errors.lastName}</div>}
        </div>
      </div>
      {/* <br /> */}

      <div className="form-group-1">
        <div className="input-container">
          <input
            className="forminput"
            id="mobileNumber"
            type="text"
            name="mobileNumber"
            placeholder="Mobile Number"
            value={values.mobileNumber}
            onChange={handleChange}
          />
          {errors.mobileNumber && (
            <div className="errorcss">{errors.mobileNumber}</div>
          )}
        </div>

        <div className="input-container">
          <input
            className="forminput"
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <div className="errorcss">{errors.email}</div>}
        </div>
      </div>
      {/* <br /> */}

      <div className="form-group-1">
        <div className="input-container">
          <textarea
            className="textarea-input"
            id="address"
            // type="text"
            name="address"
            placeholder="Address"
            value={values.address}
            onChange={handleChange}
          />
          {errors.address && <div className="errorcss">{errors.address}</div>}
        </div>
      </div>
      {/* <br /> */}

      <div className="form-group-1">
        <div className="input-container">
          <input
            className="forminput"
            id="city"
            type="text"
            name="city"
            placeholder="City"
            value={values.city}
            onChange={handleChange}
          />
          {errors.city && <div className="errorcss">{errors.city}</div>}
        </div>

        <div className="input-container">
          <input
            className="forminput"
            id="state"
            type="text"
            name="state"
            placeholder="State"
            value={values.state}
            onChange={handleChange}
          />
          {errors.state && <div className="errorcss">{errors.state}</div>}
        </div>
      </div>

      {/* <br /> */}

      <div className="form-group-1">
        <div className="input-container">
          <input
            className="forminput"
            id="country"
            type="text"
            name="country"
            placeholder="Country"
            value={values.country}
            onChange={handleChange}
          />
          {errors.country && <div className="errorcss">{errors.country}</div>}
        </div>
        <div className="input-container">
          <input
            className="forminput"
            id="pincode"
            type="text"
            name="pincode"
            placeholder="Pincode"
            value={values.pincode}
            onChange={handleChange}
          />
          {errors.pincode && <div className="errorcss">{errors.pincode}</div>}
        </div>
      </div>

      {/* <br /> */}

      <div className="formbutton">
        <button type="submit" className="btnstep1">
          Add +
        </button>
      </div>
      {/* </div>
{isLoader && <Loader/>}
            </div> */}
    </form>

  );
}

export default AddCustomer;
