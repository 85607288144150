export const months = [
    { value: 1 , label: 'Jan'},
    { value: 2 , label: 'Feb'},
    { value: 3 , label: 'Mar'},
    { value: 4 , label: 'Apr'},
    { value: 5 , label: 'May'},
    { value: 6 , label: 'Jun'},
    { value: 7 , label: 'Jul'},
    { value: 8 , label: 'Aug'},
    { value: 9 , label: 'Sep'},
    { value: 10, label: 'Oct'},
    { value: 11, label: 'Nov'},
    { value: 12, label: 'Dec'},
];


export const getYears = () => {
    const currentYear = new Date().getFullYear();
    return [
        { value: currentYear.toString(), label: currentYear.toString() },
        { value: (currentYear - 1).toString(), label: (currentYear - 1).toString() }
    ];
};